import {useEffect, useState, createRef} from "preact/compat";
import {html, render} from 'htm/preact';
import {Tooltip} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import SUPPORTED_EPSG from "../../../epsg.json";
import {route} from "preact-router";

function CreateProject() {

  const globalRef = createRef();

  const [project, setProject] = useState({title: "", place_common_name: "", place_proper_name: "", project_epsg: ""});

  const [title, setTitle] = useState("");
  const [commonName, setCommonName] = useState("");
  const [properName, setProperName] = useState("");
  const [epsg, setEpsg] = useState("");

  const [creating, setCreating] = useState(false);
  const [showMessage, setShowMessage] = useState({show: false, message: '', type: 'success'});

  useEffect(() => {

    globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));;

  }, [])

  const handleCreateProject = async () => {

    if(title.length === 0 || commonName.length === 0 || properName.length === 0 || epsg.length === 0) {
      setShowMessage({show: true, message: "Please fill out all of the required fields indicated by a *.", type: 'warning'});
      return;
    }

    setCreating(true);

    const data = await window.app.create_project({title, place_common_name: commonName, place_proper_name: properName, project_epsg: epsg});

    setShowMessage({show: true, message: data.success ? 'Successfully created a new project.' : data.data, type: data.success ? 'success' : 'error'});
    setCreating(false);

  }

  const setToolTip = (title, message) => {
    return `
        <div>
            <h6>${title}</h6>
            <p>
            ${message}
            </p>
        </div>
          `
  }

  return html`

      <div ref=${globalRef}>
      <div class="d-flex aap-shadow bg-gray p-3 mb-3 mt-3">
        <div class="w-100">
 
          <div>
              
            <h3>Create Project</h3>
            <hr />
              <div id="status-messages" class=${`${showMessage.type === 'success' ? 'success-message' : showMessage.type === 'warning' ? 'warning-message' : 'error-message'} mb-3 ${showMessage.show ? '' : 'd-none'}`} onclick=${() => {setShowMessage({show: false, message: '', type: 'error'})}}>
                <div></div>
                ${showMessage.message}
              </div>

              <div class="mb-3 w-100-md w-25-md-up">
                <div class="d-flex justify-content-between">
                  <label htmlFor="project-title" class="form-label">Project Title *</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title=${setToolTip('Project Title', 'This input field is for setting the Project Title for the project you are creating.')}>
                          <i class="fa-light fa-circle-question "/>
                      </span>
                </div>
                <div class="input-group">
                  <input type="text" class="form-control aap-input" id="project-title" placeholder="Project Title" onchange=${(e) => {
                    setTitle(e.target.value)
                  }} value=${title}/>
                </div>
              </div>
            
              <div class="d-flex-md-up mb-3 w-100-md w-50-md-up">

                <div class="me-md-up-2">
                  <div class="d-flex justify-content-between">
                    <label htmlFor="common-name" class="form-label">Common Name *</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title=${setToolTip('Common Name', 'This input field is for setting the Common Name for the project you are creating.')}>
                        <i class="fa-light fa-circle-question "/>
                    </span>
                  </div>
                  <div class="input-group">
                    <input type="text" class="form-control aap-input" id="common-name" placeholder="Common Name" onchange=${(e) => {
                      setCommonName(e.target.value)
                    }} value=${commonName}/>
                  </div>
                </div>
  
                <div class="mt-md-down-2">
                  <div class="d-flex justify-content-between">
                    <label htmlFor="proper-name" class="form-label">Proper Name *</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right" title=${setToolTip('Proper Name', 'Proper Name explanation goes here.')}><i
                        class="fa-light fa-circle-question"/></span>
                  </div>
                  <div class="input-group">
                    <input type="text" class="form-control aap-input" id="proper-name" placeholder="Proper Name" onchange=${(e) => {
    setProperName(e.target.value)
  }} value=${properName}/>
                  </div>
                </div>
                
              </div>
  
              <div class="mb-3">
                <label htmlFor="module-type" class="form-label">Project EPSG *</label>
                <div class="d-flex">
                  <input type="number" class="form-control aap-input me-1" onchange=${(e) => {
                    setEpsg(e.target.value)
                  }} value=${epsg}/>

                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    </button>

                    <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                      ${
                          SUPPORTED_EPSG.map((_epsg) => html`
                            <li class="dropdown-item" onclick=${() => {
                              setEpsg(_epsg[0]);
                            }}>
                              <span class="me-2">${_epsg[0]},</span>
                              <span>${_epsg[1]}</span>
                            </li>
                          `)
                      }
                    </ul>
                  </div>

                </div>
                
                
              </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-secondary me-2" onclick=${() => {route('/')}}>Cancel</button>
              <button class=${`btn btn-outline-primary-aap ps-4 pe-4 ${creating ? 'disable-btn' : ''}`} onclick=${handleCreateProject}>${creating ? html`Loading <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i>` : 'Create Project'}</button>
            </div>
            
          </div>
        </div>

      </div>
    </div>
  `;
}

export default CreateProject;