import {createRef, useEffect, useState} from "preact/compat";
import {Tooltip} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import {html} from "htm/preact";
import {route} from "preact-router";

const ModuleInfo = ({project_id, module_id}) => {

  const globalRef = createRef();

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [selectedModuleType, setSelectedModuleType] = useState("");
  const [moduleTypes, setModuleTypes] = useState(null);

  const [showState, setShowState] = useState(null);
  const [assets, setAssets] = useState(null);
  const [threats, setThreats] = useState(null);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedThreat, setSelectedThreat] = useState(null);

  const [assessmentType, setAssessmentType] = useState(['exposed']);

  const [enabled, setEnabled] = useState(true);

  const handleCreate = async () => {

    if(title.length === 0 || id.length === 0 || selectedModuleType.length === 0) {
      console.log("Must fill out all of the required fields.");
      return;
    }

    let body = {
      access_token: window.auth_manager.has_cognito_support ? window.auth_manager.cognito_tokens.access_token : "",
      label: title,
      project_id: project_id,
      module_id: id,
      enabled: enabled,
      asset_id: selectedAsset.asset_id,
      threat_id: selectedThreat.threat_id,
      assessment_types: assessmentType
    }

    // if(selectedAsset !== null) {
    //   body = {
    //     ...body,
    //     ...selectedAsset
    //   }
    // }
    //
    // if(selectedThreat !== null) {
    //   body = {
    //     ...body,
    //     ...selectedThreat
    //   }
    // }

    await (fetch(`${window.auth_manager.application_api_url}/${project_id}/modules`, {
      method: 'PUT',
      body: JSON.stringify(body),
      cors: true
    }).then(async (response) => {

      console.log("Response:", response);

      const json_response = await response.json();
      console.log("Json:", json_response);

      if (response.status === 200) {
        window.app.module_flow_state_stream.next(0);
      }

    }).catch(window.auth_manager._access_denied_response));

  }

  const handlePrevious = () => {
    //route(`/${project_id}/m/${module_id}/threat-info/`);
  }

  const moduleLabelTooltip = () => {
    return `
            <div>
                <h6>Module Label</h6>
                <p>
                This input field is for setting the Module Label for your project.
                </p>
            </div>
        `
  }

  const moduleIdTooltip = () => {
    return `
            <div>
                <h6>Module ID</h6>
                <p>
                This input field is for setting the Module ID for your project.
                </p>
            </div>
        `
  }

  const handleAssessmentType = (type) => {
    if(assessmentType.includes(type)) return;

    setAssessmentType([...assessmentType, type]);
  }

  const handleRemoveAssessmentType = (type) => {
    if(!assessmentType.includes(type)) return;

    if(type === 'exposed') return;

    setAssessmentType(assessmentType.filter(a => a !== type));
  }

  useEffect(() => {

    if (project_id) {

      window.app.fetch_assets(project_id);
      window.app.fetch_threats(project_id);

      globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));

      setModuleTypes(window.app.project.config.module_types);

      const subscription = window.app.module_flow_state_stream.subscribe((data) => {
        setShowState(data);
      });

      const asset_stream = window.app.assets_stream.subscribe((data) => {
        setAssets(data);

        console.log(data);
      })

      const threat_stream = window.app.threats_stream.subscribe((data) => {
        setThreats(data);
      })

      return () => {
        subscription.unsubscribe();
        asset_stream.unsubscribe();
        threat_stream.unsubscribe();
      };
    }

  }, []);

  useEffect(() => {

    if(selectedThreat === null) return;

    console.log(selectedThreat);

    let _assessment_types = [];
    _assessment_types.push('exposed');

    if(selectedThreat.hasOwnProperty('risk')) {
      if(selectedThreat.risk) {
        _assessment_types.push('risk');
      }
    }

    if(selectedThreat.hasOwnProperty('vulnerability')) {
      if(selectedThreat.vulnerability) {
        _assessment_types.push('vulnerability');
      }
    }

    if(selectedThreat.hasOwnProperty('combined_vulnerability_risk')) {
      if(selectedThreat.combined_vulnerability_risk) {
        _assessment_types.push('combined_vulnerability_risk');
      }
    }

    setAssessmentType(_assessment_types);

  }, [selectedThreat]);

  return html`
          <div ref=${globalRef} class="d-flex aap-shadow bg-gray p-3 mb-3">

            <div class="d-flex flex-column justify-content-between ms-5 w-50" style="min-height: 30rem">

              <div class="d-flex flex-column justify-content-between mb-3 w-100">
                <div class="mb-4">
                  <div class="d-flex justify-content-between">
                    <label htmlFor="module-label" class="form-label">Module Label *</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right" title=${moduleLabelTooltip()}>
                      <i class="fa-light fa-circle-question "/>
                    </span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control aap-input" id="module-label"
                           placeholder="Module Label" onchange=${(e) => {
                      setTitle(e.target.value)
                    }} value=${title}/>
                  </div>
                </div>

                <div>
                  <div class="d-flex justify-content-between">
                    <label htmlFor="module-id" class="form-label">Module ID *</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right" title=${moduleIdTooltip()}>
                      <i class="fa-light fa-circle-question "/>
                    </span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control aap-input" id="module-id"
                           placeholder="Module ID" onchange=${(e) => {
                      setId(e.target.value)
                    }} value=${id}/>
                  </div>
                </div>

                <div>
                  <label htmlFor="module-type" class="form-label">Module Type *</label>
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="module-type-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                      ${selectedModuleType.length === 0 ? 'Module Type' : moduleTypes === null ? 'Module Type' : moduleTypes[selectedModuleType].module_type_title}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="module-type-dropdown">
                      ${
                          moduleTypes === null ? ''
                              :
                              Object.keys(moduleTypes).map((module, index) => html`
                                <li key=${index} class="dropdown-item d-flex justify-content-between" onclick=${() => {setSelectedModuleType(module)}}>
                                  <span>${moduleTypes[module].module_type_title}</span>
                                  <span data-bs-toggle="tooltip" data-bs-html="true"
                                        data-bs-placement="right" title=${moduleTypes[module].module_type_description}>
                                    <i class="fa-light fa-circle-question "/>
                                  </span>
                                </li>
                            `)
                      }
                    </ul>
                  </div>

                </div>
                
              </div>

              <hr/>

              <div class="mb-3">
                <label htmlFor="module-type" class="form-label">Asset</label>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="assets-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    ${selectedAsset === null ? 'Assets' : assets === null ? 'Assets' : `${selectedAsset.label} (${selectedAsset.asset_id})`}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="assets-dropdown">
                    ${
                        assets === null ? ''
                            :
                            assets.map((asset, index) => html`
                                <li key=${index} class="dropdown-item d-flex justify-content-between" onclick=${() => {setSelectedAsset(asset)}}>
                                  <span class="me-2">${asset.label} (${asset.asset_id})</span>
                                  <span data-bs-toggle="tooltip" data-bs-html="true"
                                        data-bs-placement="right" title=${asset.commentary}>
                                    <i class="fa-light fa-circle-question "/>
                                  </span>
                                </li>
                            `)
                    }
                  </ul>
                </div>
              </div>

              <div class="mb-3">
                <label htmlFor="module-type" class="form-label">Threat</label>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="threats-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    ${selectedThreat === null ? 'Threats' : threats === null ? 'Threat' : `${selectedThreat.label} (${selectedThreat.threat_id})`}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="threats-dropdown">
                    ${
                        threats === null ? ''
                            :
                            threats.map((threat) => html`
                                <li class="dropdown-item d-flex justify-content-between" onclick=${() => {setSelectedThreat(threat)}}>
                                  <span class="me-2">${threat.label} (${threat.threat_id})</span>
                                  <span data-bs-toggle="tooltip" data-bs-html="true"
                                        data-bs-placement="right" title=${threat.commentary}>
                                    <i class="fa-light fa-circle-question "/>
                                  </span>
                                </li>
                            `)
                    }
                  </ul>
                </div>
              </div>

              <div class="mb-3">
                <label htmlFor="module-type" class="form-label">Assessment Types</label>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="assessment-types-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    Select
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="assessment-types-dropdown">
                    <li class="dropdown-item d-flex justify-content-between" onclick=${() => {handleAssessmentType('risk')}}>Risk</li>
                    <li class="dropdown-item d-flex justify-content-between" onclick=${() => {handleAssessmentType('vulnerability')}}>Vulnerability</li>
                    <li class="dropdown-item d-flex justify-content-between" onclick=${() => {handleAssessmentType('combined_vulnerability_risk')}}>Combined Vulnerability & Risk</li>
                  </ul>
                </div>
                
                <div class="d-flex">
                  
                  ${
                    assessmentType.map(a => (
                        html`
                          <div style="background-color: #3bb482; color: white;" class="me-1 mt-2" onclick=${() => {handleRemoveAssessmentType(a)}}>
                            <span className="p-1 m-1">${a}</span>
                          </div>
                        `
                    ))
                  }
                  
                </div>
              </div>
              
              
              <div class="d-flex justify-content-end mt-auto">
                <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous</button>
                <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${() => {handleCreate(module_id)}}>Create Module</button>
              </div>
            </div>

          </div>
  `;
}


export default ModuleInfo;
