import {html} from "htm/preact";

const RouteComp = (props) => {

  return html`
    ${
      props.components.map(c => (
          html`
              <${c} path="${props.path}" project_id="${props.project_id}"/>
            `
      ))
  }
  `
}

export default RouteComp;