import {html} from "htm/preact";
import {createRef, useEffect, useState} from "preact/compat";
import {Tooltip} from "../../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";

function ThreatDescription({show, title, tooltip, handleData}) {

  const globalRef = createRef();

  const [low, setLow] = useState("");
  const [med, setMed] = useState("");
  const [high, setHigh] = useState("");

  const setToolTip = (title, message) => {
    return `
        <div>
            <h6>${title}</h6>
            <p>
            ${message}
            </p>
        </div>
    `
  }

  useEffect(() => {
    globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));
  }, [])

  useEffect(() => {
    let data = [];

    data.push(low);
    data.push(med);
    data.push(high);

    handleData(data.filter(d => d.length > 0));
  }, [low, med, high])

  return html`
    <div ref=${globalRef} class="${show ? 'mb-2' : 'd-none'}">
      <div class="d-flex justify-content-between fw-bold">
        <label htmlFor="probability-low" class="form-label">${title}</label>
        <span data-bs-toggle="tooltip" data-bs-html="true"
              data-bs-placement="right" title=${setToolTip(tooltip.title, tooltip.desc)}><i
            class="fa-light fa-circle-question "/></span>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control aap-input"
               placeholder="Low Description" onchange=${(e) => {
          setLow(e.target.value)
        }} value=${low}/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control aap-input"
               placeholder="Medium Description" onchange=${(e) => {
          setMed(e.target.value)
        }} value=${med}/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control aap-input"
               placeholder="High Description" onchange=${(e) => {
          setHigh(e.target.value)
        }} value=${high}/>
      </div>
    </div>

  `
}

export default ThreatDescription;