import {html} from "htm/preact";
import {useState, useEffect} from "preact/hooks";

/**
 * Provides a link to users to see more about users they know via a shared project, otherwise does a decent job of printing user ids.
 */
const UserLink = ({user_id}) => {
  const [user_nicename, set_usernicename] = useState(null)
  useEffect(() => {
    window.app.user_users_stream.subscribe((known_users) => {
      if (!known_users){
        return
      }
      const user = known_users.find(a=>a.sub === user_id)
      if (user){
        set_usernicename(user.nicename)
      }
    })
  }, [])
  // TODO some kind of user profiles page? Dunno.
  // TODO maybe people will want little profile pics on these?
  return html`
    ${user_nicename ? html`
      <a href="/?u=${user_id}">${user_nicename}</a>
    ` : html`<span title="User: ${user_id}">${user_id.slice(0, 8) + "…"}</span>`}
  `;

}

export default UserLink