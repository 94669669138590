import AssetInfo from "./modify_flow/asset_info.js";
import ThreatInfo from './modify_flow/threat_info.js';
import ModuleInfo from './modify_flow/module_info.js';
import Datasets from "./module_flow/datasets.js";
import DatasetsLayerInfo from "./module_flow/datasets_layer_info.js";
import AssetTags from "./modify_flow/asset_tags.js";
import AssessmentRules from "./module_flow/assessment_rules.js";
import Review from "./module_flow/review.js";
import {html} from "htm/preact";
import Router from 'preact-router';
import {createHashHistory} from 'history';
import {Link} from 'preact-router/match';
import ModuleFlow from "./module_flow/module_flow.js";
import LayersList from "./modify_flow/layers_list.js";
import FilesList from "./files_list.js";
import RouteComp from "./common_components/route_component";
import CreateProject from "./project_flow/create_project.js";
import ProjectBoundary from "./project_flow/project_boundary.js";
import {useEffect, useState} from "preact/compat";
import {Modal} from "../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";

const ModifyHeader = ({project_id}) => {

  const [username, setUsername] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [active, setActive] = useState('layers');

  useEffect(() => {

    if(projects.length === 0) return;

    const _project = projects.find(p => p.project_id === project_id);

    if(typeof _project === 'undefined') return;

    setProject(_project);

  }, [projects]);

  useEffect(() => {

    const tab = window.location.href.split('/').slice(-1)[0];

    if(typeof tab !== 'undefined') {

      switch(tab) {
        case 'layers':
          setActive('Layers');
          break;
        case 'asset-tags':
          setActive('Asset Tags');
          break;
        case 'assets':
          setActive('Assets');
          break;
        case 'threats':
          setActive('Threats');
          break;
        default:
          setActive('Modules');
      }

    }

    const projects_stream = window.app.user_projects_stream.subscribe(data => {
      setProjects(data);
    })

    return () => {
      projects_stream.unsubscribe();
    }

  }, []);

  const handleInviteUser = () => {
    if(username.length === 0) return;

    if(!projects || projects.length === 0) return;

    window.app.add_collaborator(projects.find(p => p.project_id === project_id), username).then(() => {
      Modal.getOrCreateInstance(document.querySelector('#add-user-modal')).hide();
    });

  }

  return html`
    <div class="d-flex align-items-center">

      <div class="w-100 mt-3 d-none-md-up">
        <div class="dropdown mb-2">
          <button class="btn btn-secondary dropdown-toggle w-100-md" type="button" id="modify-routes-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
            ${active}
          </button>
          <ul class="dropdown-menu w-100" aria-labelledby="modify-routes-dropdown">
            <li class="dropdown-item modify-item d-flex align-items-center ${active === 'Layers' ? 'active' : ''}" onclick=${() => {window.location.href = `/modify.html#/${project_id}/layers`; setActive('Layers')}}>Layers</li>
            <li class="dropdown-item modify-item d-flex align-items-center ${active === 'Asset Tags' ? 'active' : ''}" onclick=${() => {window.location.href = `/modify.html#/${project_id}/asset-tags`; setActive('Asset Tags')}}>Asset Tags</li>
            <li class="dropdown-item modify-item d-flex align-items-center ${active === 'Assets' ? 'active' : ''}" onclick=${() => {window.location.href = `/modify.html#/${project_id}/assets`; setActive('Assets')}}>Assets</li>
            <li class="dropdown-item modify-item d-flex align-items-center ${active === 'Threats' ? 'active' : ''}" onclick=${() => {window.location.href = `/modify.html#/${project_id}/threats`; setActive('Threats')}}>Threats</li>
            <li class="dropdown-item modify-item d-flex align-items-center ${active === 'Modules' ? 'active' : ''}" onclick=${() => {window.location.href = `/modify.html#/${project_id}/modules`; setActive('Modules')}}>Modules</li>
          </ul>
        </div>
        
        <div class="d-flex">
          <button class="btn w-50 btn-outline-dark" onclick=${() => Modal.getOrCreateInstance(document.querySelector('#add-user-modal')).show()}><i class="fa fa-user-plus fa-small me-2"></i>Invite Others</button>
          <button class="btn w-50 btn-outline-dark" onclick=${() => Modal.getOrCreateInstance(document.querySelector('#manage-users-modal')).show()}><i class="fa fa-users fa-small me-2"></i>Manage Users</button>
        </div>
      </div>
      
      <div class="d-flex mt-3 d-none-md">
        <div class="modify-item">
          <${Link} activeClassName="active" class="modify-link" aria-current="page" href="/${project_id}/layers">Layers</${Link}>
        </div>
        
        <div class="modify-item">
          <${Link} activeClassName="active" class="modify-link" aria-current="page" href="/${project_id}/asset-tags">Asset Tags</${Link}>
        </div>
        
        <div class="modify-item">
          <${Link} activeClassName="active" class="modify-link" aria-current="page" href="/${project_id}/assets">Assets</${Link}>
        </div>
        
        <div class="modify-item">
          <${Link} activeClassName="active" class="modify-link" aria-current="page" href="/${project_id}/threats">Threats</${Link}>
        </div>
        
        <div class="modify-item">
          <${Link} activeClassName="active" class="modify-link" aria-current="page" href="/${project_id}/modules">Modules</${Link}>
        </div>
      </div>

      <div class="ms-auto d-none-md">
        <button class="btn btn-sm btn-outline-dark me-1" onclick=${() => Modal.getOrCreateInstance(document.querySelector('#add-user-modal')).show()}><i class="fa fa-user-plus fa-small me-2"></i>Invite Others</button>
        <button class="btn btn-sm btn-outline-dark" onclick=${() => Modal.getOrCreateInstance(document.querySelector('#manage-users-modal')).show()}><i class="fa fa-users fa-small me-2"></i>Manage Users</button>
      </div>

      <div id="add-user-modal" class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Invite User</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="input-group mb-3">
                <input type="text" class="form-control aap-input" placeholder="Username" onchange=${(e) => {
                  setUsername(e.target.value)
                }} value=${username}/>
                <button class="btn btn-primary" onclick=${handleInviteUser}>Invite</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="manage-users-modal" class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Manage Users</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              ${project !== null ? html`<div>${
                project.collaborators.map(u => (
                  html`<div>${u}</div>`
              ))
              }</div>` : '' }
            </div>
          </div>
        </div>
      </div>
      
    </div>
  `
}

const ModifyRoot = () => {

  return html`
      <${Router} history=${createHashHistory()} id="modify-container">
        <${FilesList} path="/:project_id/files"/>
        <${CreateProject} path="/:project_id/project-info"/>
        <${ProjectBoundary} path="/:project_id/project-boundary"/>
        
        <${RouteComp} path="/:project_id/layers" components=${[ModifyHeader, LayersList]} />
        <${RouteComp} path="/:project_id/asset-tags" components=${[ModifyHeader, AssetTags]} />
        <${RouteComp} path="/:project_id/assets" components=${[ModifyHeader, AssetInfo]} />
        <${RouteComp} path="/:project_id/threats" components=${[ModifyHeader, ThreatInfo]} />
        <${RouteComp} path="/:project_id/modules" components=${[ModifyHeader, ModuleFlow]} />
        
        <${ModuleInfo} path="/:project_id/m/:module_id/module-info/"/>
        <${Datasets} path="/:project_id/m/:module_id/datasets/"/>
        <${DatasetsLayerInfo} path="/:project_id/m/:module_id/datasets-info/"/>
        
        <${AssessmentRules} path="/:project_id/m/:module_id/assessment-rules/"/>
        <${Review} path="/:project_id/m/:module_id/review/"/>
      <//>
  `
}

export default ModifyRoot;

// This one inits itself ASAP
//render(html`<${ModifyRoot}/>`, document.querySelector('body > div.container'))