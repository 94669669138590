import {createRef, useEffect, useState} from "preact/compat";
import {Tooltip, Modal} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import {html} from "htm/preact";

import ThreatDescription from "./threat_sub_components/threat_descriptions.js";
import ThreatCondition from "./threat_sub_components/threat_conditions.js";
import {shortenName} from "../../utils";

const ThreatInfo = ({project_id}) => {

  const globalRef = createRef();

  const [threatLabel, setThreatLabel] = useState("");
  const [threatAbbreviation, setThreatAbbreviation] = useState("");
  const [threatId, setThreatId] = useState("");
  const [threatDesc, setThreatDesc] = useState("");

  const [threats, setThreats] = useState(null);
  const [layers, setLayers] = useState(null);

  const [floodSourceLayers, setFloodSourceLayers] = useState([]);
  const [regulatoryFloodSourceLayers, setRegulatoryFloodSourceLayers] = useState([]);

  const [risk, setRisk] = useState(false);
  const [vulnerability, setVulnerability] = useState(false);
  const [combined, setCombined] = useState(false);

  const [riskConsequenceDesc, setRiskConsequenceDesc] = useState(null);
  const [riskConsequenceCond, setRiskConsequenceCond] = useState(null);

  const [riskProbabilityDesc, setRiskProbabilityDesc] = useState(null);
  const [riskProbabilityCond, setRiskProbabilityCond] = useState(null);

  const [vulPotentialImpactDesc, setVulPotentialImpactDesc] = useState(null);

  const [vulAdaptiveCapacityDesc, setVulAdaptiveCapacityDesc] = useState(null);
  const [vulAdaptiveCapacityCond, setVulAdaptiveCapacityCond] = useState(null);

  const [showMessage, setShowMessage] = useState({show: false, message: '', type: 'success'});
  const [creating, setCreating] = useState(false);

  const [deleteThreat, setDeleteThreat] = useState(null);

  /*
  Create an asset that is stored locally for the time being. Once created, push to asset_stream.
   */
  const handleCreateThreat = async () => {

    if (threatLabel.length === 0 || threatId.length === 0 || threatDesc.length === 0) {
      setShowMessage({show: true, message: "Please fill out all of the required fields indicated by a *.", type: 'warning'});
      return;
    }

    /* If the list of threats is still null then we don't have any threats created, otherwise search and see if we have an threat with the same
       threat_id, if we do then return true, otherwise return false */
    const threatExists = threats === null ? false : (typeof threats.find(a => a.threat_id === threatId) === 'undefined' ? false : true);

    if (threatExists) {
      setShowMessage({show: true, message: `There is already a threat with the id ${threatId}`, type: 'warning'});
      return;
    }

    const threat_obj = {
      threat_id: threatId,
      label: threatLabel,
      commentary: threatDesc,
      abbreviation: threatAbbreviation.length > 3 ? threatAbbreviation.slice(0, 3).toUpperCase() : threatAbbreviation.toUpperCase(),
      layer: floodSourceLayers.length > 0 ? floodSourceLayers.map(l => l.layer_id) : [],
      regulatory_flood_source_layer: regulatoryFloodSourceLayers.length > 0 ? regulatoryFloodSourceLayers.map(l => l.layer_id) : []
    }

    // Risk checkbox is selected
    if(risk) {

      if(riskConsequenceDesc === null || riskProbabilityDesc === null) {
        setShowMessage({show: true, message: "Please fill out all of the required Risk fields indicated by a *.", type: 'warning'});
        return;
      }

      if(riskConsequenceDesc.length < 3 || riskProbabilityDesc.length < 3) {
        setShowMessage({show: true, message: "Please fill out all of the required Risk fields indicated by a *.", type: 'warning'});
        return;
      }

      if(riskConsequenceCond === null || riskProbabilityCond === null) {
        setShowMessage({show: true, message: "Please fill out all of the required Risk fields indicated by a *.", type: 'warning'});
        return;
      }

      if(riskConsequenceCond.length === 0 || riskProbabilityCond.length === 0 ) {
        setShowMessage({show: true, message: "Please fill out all of the required Risk fields indicated by a *.", type: 'warning'});
        return;
      }

      threat_obj.risk = true;

      threat_obj.risk_consequence_hml_descriptions = riskConsequenceDesc;
      threat_obj.risk_consequence_conditions = riskConsequenceCond;

      threat_obj.risk_probability_hml_descriptions = riskProbabilityDesc;
      threat_obj.risk_probability_conditions = riskProbabilityCond;

    }

    // Vulnerability checkbox is selected
    if(vulnerability) {

      if(vulPotentialImpactDesc === null || vulAdaptiveCapacityDesc === null) {
        setShowMessage({show: true, message: "Please fill out all of the required Vulnerability fields indicated by a *.", type: 'warning'});
        return;
      }

      if(vulPotentialImpactDesc.length < 3 || vulAdaptiveCapacityDesc.length < 3) {
        setShowMessage({show: true, message: "Please fill out all of the required Vulnerability fields indicated by a *.", type: 'warning'});
        return;
      }

      if(vulAdaptiveCapacityCond === null) {
        setShowMessage({show: true, message: "Please fill out all of the required Vulnerability fields indicated by a *.", type: 'warning'});
        return;
      }

      if(vulAdaptiveCapacityCond.length === 0) {
        setShowMessage({show: true, message: "Please fill out all of the required Vulnerability fields indicated by a *.", type: 'warning'});
        return;
      }

      threat_obj.vulnerability = true;

      threat_obj.potential_impact_hml_descriptions = vulPotentialImpactDesc;
      threat_obj.adaptive_capacity_hml_descriptions = vulAdaptiveCapacityDesc;

      threat_obj.adaptive_capacity_conditions = vulAdaptiveCapacityCond;
    } else {
      threat_obj.vulnerability = false;
    }

    if(combined) {
      threat_obj.combined_vulnerability_risk = (risk && vulnerability ? true : false);
    }
    setCreating(true);

    const data = await window.app.create_threat(project_id, threat_obj);

    setShowMessage({show: true, message: data.success ? 'Successfully created a threat.' : data.data, type: data.success ? 'success' : 'error'});
    setCreating(false);
  }

  const handleCancel = () => {
    setThreatLabel("");
    setThreatId("");
    setThreatDesc("");
  }

  const handleDeleteThreat = async (id) => {

    if(id === null) return;

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-threat-modal'));
    modal.hide();

    const success = await window.app.delete_threat(project_id, id);

    if(success) {
      setShowMessage({show: true, message: 'Successfully deleted a threat.', type: 'success'});
    }
  }

  const setToolTip = (title, message) => {
    return `
        <div>
            <h6>${title}</h6>
            <p>
            ${message}
            </p>
        </div>
    `
  }

  const handleRemoveSelectedLayer = (selectedLayer, setSelectedLayer, layer) => {
    let clone = [...selectedLayer];
    clone = clone.filter(l => l.layer_id !== layer.layer_id);
    setSelectedLayer(clone);
  }

  useEffect(() => {

    globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));

    if (project_id) {

      window.app.fetch_layers(project_id);
      window.app.fetch_threats(project_id);

      const threats_stream = window.app.threats_stream.subscribe((data) => {
        setThreats(data);
      });

      const layers_stream = window.app.layers_stream.subscribe((data) => {
        if (data !== null) {
          setLayers(data);
        }
      });

      return () => {
        threats_stream.unsubscribe();
        layers_stream.unsubscribe();
      };
    }

  }, []);

  useEffect(() => {

    if(showMessage.show) {
      document.getElementById('status-messages').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }, [showMessage]);

  useEffect(() => {

    if(threats !== null && threats.length > 0) {

      // We don't want the tooltip to be registered again if it already exists.
      globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => {
        if(Tooltip.getInstance(element) === null) {
          new Tooltip(element)
        }
      });

    }

  }, [threats]);

  useEffect(() => {

    if(deleteThreat === null) return;

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-threat-modal'));
    modal.show();

  }, [deleteThreat]);

  return html`
    <div ref=${globalRef}>
      <div class="d-flex bg-gray aap-shadow p-3 mb-3">
        <div class="d-flex flex-column flex-grow-1 justify-content-between" style="min-height: 30rem">
          
          <div class="d-flex-lg-up">
          
          <div class="w-50-lg-up">

            <div id="status-messages" class=${`${showMessage.type === 'success' ? 'success-message' : showMessage.type === 'warning' ? 'warning-message' : 'error-message'} mb-3 ${showMessage.show ? '' : 'd-none'}`} onclick=${() => {setShowMessage({show: false, message: '', type: 'error'})}}>
              <div></div>
              ${showMessage.message}
            </div>
            
            <div class="d-flex-md-up mb-3 w-100">
              <div>
                <div class="d-flex justify-content-between">
                  <label htmlFor="threat-label" class="form-label">Threat Label</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('Threat Label', 'This input field is for setting the Threat Label for your project.')}><i
                      class="fa-light fa-circle-question "/></span>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control aap-input" id="threat-label"
                         placeholder="Threat Label" onchange=${(e) => {
                    setThreatLabel(e.target.value)
                  }} value=${threatLabel}/>
                </div>
              </div>

              <div class="ms-md-up-2 me-md-up-2">
                <div class="d-flex justify-content-between">
                  <label htmlFor="threat-id" class="form-label">Threat ID</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('Threat ID', 'This input field is for setting the Threat ID for your project.')}><i
                      class="fa-light fa-circle-question "/></span>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control aap-input" id="threat-id"
                         placeholder="Threat ID" onchange=${(e) => {
                    setThreatId(e.target.value)
                  }} value=${threatId}/>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between">
                  <label htmlFor="threat-abbreviation" class="form-label">Threat Abbreviation</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('Threat Abbreviation', '1-3 character abbreviation of id to be used for auto-generating abbreviated column names for final output files in AAE.')}><i
                      class="fa-light fa-circle-question"/></span>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control aap-input" id="threat-abbreviation" placeholder="Threat Abbreviation" onchange=${(e) => {
                    setThreatAbbreviation(e.target.value)
                  }} value=${threatAbbreviation}/>
                </div>
              </div>

            </div>

            <div class="mb-3">
              <div class="d-flex justify-content-between">
                <label htmlFor="threat-description" class="form-label">Threat Description</label>
                <span data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right" title=${setToolTip('Threat Description', 'This input field is for setting the Threat Description for your project.')}><i
                    class="fa-light fa-circle-question "/></span>
              </div>
              <textarea class="form-control" id="threat-description" rows="8" onchange=${(e) => {
                setThreatDesc(e.target.value)
              }} />
            </div>

            <hr/>
            <!--- https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array --->
            <div class="mb-3">
              <label htmlFor="module-type" class="form-label">Flood Source Layers</label>
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  Flood Layers
                </button>
                <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                  ${
                      layers === null ? ''
                          :
                          layers.map((layer, index) => html`
                            <li key=${index} class="dropdown-item d-flex justify-content-between" onclick=${() => {
                              setFloodSourceLayers([...new Set([...floodSourceLayers, layer])])
                            }}>
                              <span class="me-2">${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>
                              <span data-bs-toggle="tooltip" data-bs-html="true"
                                    data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file, 25)}>
                                      <i class="fa-light fa-circle-question "/>
                                    </span>
                            </li>
                          `)
                  }
                </ul>
              </div>

              <div class="mt-2 d-flex flex-wrap">
                ${
                    typeof floodSourceLayers !== 'undefined' && floodSourceLayers.length > 0 ?
                        floodSourceLayers.map((layer, index) => (

                            html`<span class="selected-layer me-2" onclick=${() => {
                              handleRemoveSelectedLayer(floodSourceLayers, setFloodSourceLayers, layer)
                            }}>${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>`

                        ))

                        :
                        ''
                }
              </div>

            </div>

            <div class="mb-3">
              <label htmlFor="module-type" class="form-label">Regulatory Flood Source Layers</label>
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="regulatory-layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  Flood Layers
                </button>
                <ul class="dropdown-menu" aria-labelledby="regulatory-layers-dropdown">
                  ${
                      layers === null ? ''
                          :
                          layers.map((layer, index) => html`
                            <li key=${index} class="dropdown-item d-flex justify-content-between" onclick=${() => {
                              setRegulatoryFloodSourceLayers([...new Set([...regulatoryFloodSourceLayers, layer])])
                            }}>
                              <span class="me-2">${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>
                              <span data-bs-toggle="tooltip" data-bs-html="true"
                                    data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file, 25)}>
                                      <i class="fa-light fa-circle-question "/>
                                    </span>
                            </li>
                          `)
                  }
                </ul>
              </div>

              <div class="mt-2 d-flex flex-wrap">
                ${
                    typeof regulatoryFloodSourceLayers !== 'undefined' && regulatoryFloodSourceLayers.length > 0 ?
                        regulatoryFloodSourceLayers.map((layer, index) => (

                            html`<span class="selected-layer me-2" onclick=${() => {
                              handleRemoveSelectedLayer(regulatoryFloodSourceLayers, setRegulatoryFloodSourceLayers, layer)
                            }}>${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>`

                        ))

                        :
                        ''
                }
              </div>

            </div>

            <div class="mb-3">

              <div class="d-flex justify-content-between mb-3">
                <div class="form-check">
                  <label class="form-check-label me-5" for="risk">
                    Risk
                  </label>
                  <input class="form-check-input" type="checkbox" value="" onchange=${() => {
                    setRisk(!risk)
                  }} id="risk" checked=${risk}/>
                </div>

                <span data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right" title=${setToolTip('Risk', 'Run risk based assessment.')}><i
                    class="fa-light fa-circle-question "/></span>
              </div>

              <!-- Risk Consequence -->
              
              <${ThreatDescription} 
                show=${risk}
                title="Risk Consequence Descriptions"
                tooltip=${{title: "Risk Consequence HML Descriptions", desc: "Denoting the text descriptions which would be displayed in AAO for high, medium, and low risk consequence."}}
                handleData=${setRiskConsequenceDesc}
              />

              <${ThreatCondition}
                  show=${risk}
                  title="Risk Consequence Conditions"
                  tooltip=${{title: "Risk Consequence Conditions", desc: "Denotes conditions which will be evaluated to determine asset risk consequence levels in modules."}}
                  buttonName="Add Condition"
                  handleData=${setRiskConsequenceCond}
              />
              
              <!-- Risk Probability -->
              
              <${ThreatDescription} 
                show=${risk}
                title="Risk Probability Descriptions"
                tooltip=${{title: "Risk Probability HML Descriptions", desc: "Denoting the text descriptions which would be displayed in AAO for high, medium, and low risk probability."}}
                handleData=${setRiskProbabilityDesc}
              />

              <${ThreatCondition}
                  show=${risk}
                  title="Risk Probability Conditions"
                  tooltip=${{title: "Risk Probability Conditions", desc: "Denotes conditions which will be evaluated to determine asset risk probability levels in modules."}}
                  buttonName="Add Condition"
                  handleData=${setRiskProbabilityCond}
              />
              
              <div class="d-flex justify-content-between mb-3">
                <div class="form-check">
                  <label class="form-check-label me-5" for="vulnerability">
                    Vulnerability
                  </label>
                  <input class="form-check-input" type="checkbox" value="" onchange=${() => {
                    setVulnerability(!vulnerability)
                  }} id="vulnerability" checked=${vulnerability}/>
                </div>

                <span data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right" title=${setToolTip('Vulnerability', 'Run vulnerability based assessment.')}><i
                    class="fa-light fa-circle-question "/></span>
              </div>

              <!-- Potential Impact -->
              
              <${ThreatDescription}
                  show=${vulnerability}
                  title="Potential Impact Description"
                  tooltip=${{title: "Potential Impact HML Description", desc: "Denoting the text descriptions which would be displayed in AAO for high, medium, and low potential impact."}}
                  handleData=${setVulPotentialImpactDesc}
              />

              <!-- Adaptive Capacity -->
              
              <${ThreatDescription}
                  show=${vulnerability}
                  title="Adaptive Capacity Descriptions"
                  tooltip=${{title: "Adaptive Capacity HML Descriptions", desc: "Denoting the text descriptions which would be displayed in AAO for high, medium, and low adaptive capacity."}}
                  handleData=${setVulAdaptiveCapacityDesc}
              />

              <${ThreatCondition}
                  show=${vulnerability}
                  title="Adaptive Capacity Conditions"
                  tooltip=${{title: "Adaptive Capacity Conditions", desc: "Denotes conditions which will be evaluated to determine asset adaptive capacity levels in modules."}}
                  buttonName="Add Condition"
                  handleData=${setVulAdaptiveCapacityCond}
              />
              
              <div class="d-flex justify-content-between mb-3">
                <div class="form-check">
                  <label class="form-check-label me-5" for="combined">
                    Combined Vulnerability and Risk
                  </label>
                  <input class="form-check-input" type="checkbox" onchange=${() => {
                    setCombined(!combined)
                  }} id="combined"  disabled=${risk && vulnerability ? false : true} />
                </div>

                <span data-bs-toggle="tooltip" data-bs-html="true"
                      data-bs-placement="right" title=${setToolTip('Combined Vulnerability and Risk', 'Denoting whether to assess combined vulnerability and risk of assets/threats (Both risk and vulnerability must be selected).')}><i
                    class="fa-light fa-circle-question "/></span>
              </div>
              
            </div>

            <div class="d-flex justify-content-end mt-auto">
              <button class="btn btn-secondary me-2" onclick=${handleCancel}>Cancel</button>
              <button class=${`btn btn-outline-primary-aap ps-4 pe-4 ${creating ? 'disable-btn' : ''}`} onclick=${handleCreateThreat}>${creating ? html`Loading <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i>` : 'Create Threat'}</button>
            </div>
            
          </div>

          <div class="w-50-lg-up left-border-lg-up top-border-lg-down ms-lg-up-3 ps-lg-up-3 mt-lg-down-3 pt-lg-down-3" style="text-align: center;">
            ${

                threats === null ? html`<div>Checking for threats <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i></div>` :
    
                  threats.length > 0 ?

                    html`
                      <div class="fw-bold mb-3">Configured Threats</div>
                      ${
                          threats.map(threat => (
                            html`
                                <div class="d-flex py-2 border-bottom">
                                  <div class="d-flex align-items-center justify-content-between me-auto">
                                    <i class="me-3 fa-light fa-circle-info" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title=${setToolTip(`ID: ${threat.asset_id}`, `Commentary: ${threat.commentary}`)}></i><span>${threat.label}</span>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-end delete-asset-threat" onclick=${() => {setDeleteThreat(Object.assign({}, threat))}}>
                                    <i class="fa-light fa-trash-can"></i>
                                  </div>
                                </div>
                                `
                        ))

                    }
                      `
                    :
                    html`<div>No Threats have been configured!</div>`
            }
          </div>

          </div>
          
        </div>
      </div>

      <div id="delete-threat-modal" class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${deleteThreat !== null ? deleteThreat.label : 'Title'}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete the threat with ID ${deleteThreat !== null ? html`<span style="color: #e74949; font-weight: bold;">${deleteThreat.threat_id}</span>` : 'id'}?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-outline-error" onclick=${() => {handleDeleteThreat(deleteThreat !== null ? deleteThreat.threat_id : null)}} >Delete</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  `;
}


export default ThreatInfo;
