import {useEffect, useState} from "preact/compat";
import {FlowNavTopContext, FlowNavSideContext} from "../flow_context.js";
import FlowNavTop from "../common_components/flow_nav_top.js";

import {html} from "htm/preact";
import {route} from "preact-router";
import LayersSidePanel from "./layers_side_panel";

const Datasets = ({project_id, module_id}) => {

  const [FlowNavTopState, setFlowNavTopState] = useState(1);
  const [FlowNavSideState, setFlowNavSideState] = useState({
    values: ["Dataset", "Layers"],
    current: 0
  });

  const [propertyLayer, setPropertyLayer] = useState(null);
  const [buildingLayer, setBuildingLayer] = useState(null);
  const [floodLayer, setFloodLayer] = useState(null);

  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedLayerType, setSelectedLayerType] = useState('property');
  const [currentState, setCurrentState] = useState(null);

  const handleNext = () => {
    route(`/${project_id}/m/${module_id}/datasets-info/`);
  }

  const handlePrevious = () => {
    route(`/${project_id}/m/${module_id}/module-info/`);
  }

  const get_selected_layer = (layer) => {

    if(!layer) return;

    if(selectedLayerType === 'property') {

      setPropertyLayer({
        parcels_source_layer: 'parcels_source',
        parcels_layer: layer.layer_id
      })

    } else if(selectedLayerType === 'building') {

      setBuildingLayer({
        buildings_source_layer: 'buildings_source',
        buildings_layer: layer.layer_id
      })

    } else if(selectedLayerType === 'flood') {

      setFloodLayer({
        flood_source_layer: 'flood_source',
        flood_layer: layer.layer_id
      })

    }

    //console.log("Getting Layer:", layer);
  }

  useEffect(() => {

    if (project_id && module_id) {

      const subscription = window.app.module_flow_stream.subscribe((data) => {
        setCurrentState(data);

        console.log("Current State:", data);

      });

      return () => {
        subscription.unsubscribe()
      };
    }

  }, []);

  useEffect(() => {

    if(propertyLayer === null || buildingLayer === null || floodLayer === null) return;

    const clone = currentState === null ? {
          parcels_source_layer: propertyLayer.parcels_source_layer,
          parcels_layer: propertyLayer.parcels_layer,
          buildings_source_layer: buildingLayer.buildings_source_layer,
          buildings_layer: buildingLayer.buildings_layer,
          flood_source_layer: floodLayer.flood_source_layer,
          flood_layer: floodLayer.flood_layer
        } :
        {
          ...currentState,
          parcels_source_layer: propertyLayer.parcels_source_layer,
          parcels_layer: propertyLayer.parcels_layer,
          buildings_source_layer: buildingLayer.buildings_source_layer,
          buildings_layer: buildingLayer.buildings_layer,
          flood_source_layer: floodLayer.flood_source_layer,
          flood_layer: floodLayer.flood_layer
        };

    console.log("Clone:", clone);

    window.app.module_flow_stream.next(clone);
  }, [propertyLayer, buildingLayer, floodLayer])

  return html`
    <${FlowNavTopContext.Provider} value=${FlowNavTopState}>
      <div class="container">
        <${FlowNavTop}/>

        <div class="d-flex justify-content-between aap-shadow bg-gray p-3 mt-3 mb-3">

          <div class="d-flex flex-column justify-content-between w-50" style="min-height: 30rem">
            <div class="mb-3">

              <div class="border p-3 bg-white ${selectedLayerType === 'property' ? 'border-active' : ''} mb-2">
                <label htmlFor="property-layer-file" class="form-label" style="font-weight: bold;">Property Layer</label>

                <div class="mb-3 d-flex justify-content-between">
                  Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-secondary" onclick=${() => {setSelectedLayerType('property')}}>Select</button>
                </div>

              </div>

              <div class="border p-3 bg-white ${selectedLayerType === 'building' ? 'border-active' : ''} mb-2">

                <label htmlFor="property-layer-file" class="form-label" style="font-weight: bold;">Building Layer</label>

                <div class="mb-3 d-flex justify-content-between">
                  Leverage agile frameworks to provide a robust synopsis for high level overviews.
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-secondary" onclick=${() => {setSelectedLayerType('building')}}>Select</button>
                </div>

              </div>

              <div class="border p-3 bg-white ${selectedLayerType === 'flood' ? 'border-active' : ''} mb-2">
                <label htmlFor="property-layer-file" class="form-label" style="font-weight: bold;">Flood Layer</label>

                <div class="mb-3 d-flex justify-content-between">
                  Leverage agile frameworks to provide a robust synopsis for high level overviews. Leverage agile frameworks to provide a robust synopsis for high level overviews.
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-secondary" onclick=${() => {setSelectedLayerType('flood')}}>Select</button>
                </div>

              </div>
            </div>

            <div class="d-flex justify-content-end mt-auto">
              <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous
              </button>
              <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${handleNext}>Next</button>
            </div>
          </div>

          <div class="mx-3" style="border-right: 1px solid #DEE2E6;"></div>

          <div class="w-50">
            <${LayersSidePanel} set_selected_layer=${get_selected_layer} visible=${true}/>
          </div>

        </div>
      </div>
    <//>

  `;
}


export default Datasets;
