import {html} from "htm/preact";
import {useEffect, useState} from "preact/compat";

const FilesList = () => {

  const [files, setFiles] = useState([{
    name: "file1_name.file",
    projects: [{title: "Project Title 1", project_id: "project_id_1"}, {title: "Project Title 2", project_id: "project_id_2"}, {title: "Project Title 3", project_id: "project_id_3"}, {title: "Project Title 4", project_id: "project_id_4"}]
  }, {
    name: "file2_name.file",
    projects: [{title: "Project Title 1", project_id: "project_id_1"}, {title: "Project Title 2", project_id: "project_id_2"}, {title: "Project Title 3", project_id: "project_id_3"}, {title: "Project Title 4", project_id: "project_id_4"}]
  }, {
    name: "file3_name.file",
    projects: [{title: "Project Title 1", project_id: "project_id_1"}, {title: "Project Title 2", project_id: "project_id_2"}, {title: "Project Title 3", project_id: "project_id_3"}, {title: "Project Title 4", project_id: "project_id_4"}]
  }]);

  useEffect(() => {
    console.log("FilesList");
  }, [])

  return html`
    <div>
      <div class="mt-3 aap-shadow p-3">
        
        ${
          files.length > 0 ?
              html`
              <div class="d-flex justify-content-between mb-2">
                <span style="font-weight: bold;">File Name</span>
                <span style="font-weight: bold;">Projects</span>
              </div>
              `
              :
              html`
              <div>No files found.</div>
              `
        }
        
        ${
          files.map(f => (
              html`
              <div class="d-flex justify-content-between border-top pt-2">
                <span>${f.name}</span>
                <div class="d-flex flex-column">
                  ${
                      f.projects.map(p => (
                          html`
                    <span class="ms-4"><a class="btn-aap-link" href="/view.html#/${p.project_id}/">${p.title}</a></span>
                    `
                      ))
                  }
                </div>
              </div>
              `
          ))
      
        }
      </div>

    </div>
  `;
}

export default FilesList;
