import {Component, render, createRef} from 'preact';
import {html} from 'htm/preact';

import {anime} from '../vendor/common.min.js';

const colors = ['#f47d5a', '#52be96']

/**
 * A dropdown-style component for users to see the progress of file uploads
 */
export default class FileUploadProgressDropdown extends Component {
  static create(allow_uploads) {
    const ref = createRef();
    render(html`
      <${FileUploadProgressDropdown} ref=${ref} allow_uploads=${allow_uploads}/>`, document.querySelector('.upload-progress').parentElement, document.querySelector('.upload-progress'))
    return ref
  }

  /**
   * Call to notify that progress has changed and we should re-render.
   */
  set_dirty() {
    if (!this.pending_progress_update) {
      // debounce updates
      this.pending_progress_update = setTimeout(() => {
        this.pending_progress_update = null;
        this.forceUpdate()
      }, 150)
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // don't update when the component is collapsed.
    return !nextState.collapsed && this.state.collapsed !== nextState.collapsed
  }

  constructor() {
    super();
    this.state = {allow_uploads: false, collapsed: true};
  }

  componentDidMount() {
    this.base.addEventListener(new CustomEvent('show.bs.dropdown'),()=>{
      this.setState({allow_uploads:this.state.allow_uploads, collapsed: false})
    });
    this.base.addEventListener(new CustomEvent('hidden.bs.dropdown'),()=>{
      this.setState({allow_uploads:this.state.allow_uploads, collapsed: true})
    });
  }

  componentDidUpdate(){
    //
  }

  animate_bubble(from_el = null, delay = 0) {
    const color = colors[anime.random(0, 1)]
    const start = from_el.getBoundingClientRect()
    const end = this.base.getBoundingClientRect()
    const bubble = document.createElement('span')
    bubble.style.borderStyle = 'solid';
    bubble.style.backgroundColor = bubble.style.borderColor = color;
    bubble.style.borderRadius = '50%';
    bubble.style.height = bubble.style.width = "0px";
    const bw1 = anime.random(4, 16) / 2;
    bubble.style.borderWidth = bw1 + 'px';
    bubble.style.position = 'fixed';
    bubble.style.zIndex = '99999';
    bubble.style.opacity = '0.9';
    bubble.style.pointerEvents = 'none';
    // start in upper left
    bubble.style.left = (start.left + (start.width * 2 / 3) + anime.random(-30, 30)) + 'px'
    bubble.style.top = (start.top + (start.height / 3) + anime.random(-30, 30)) + 'px'
    document.body.appendChild(bubble);
    anime.timeline()
      .add({
        targets: bubble,
        delay: delay,
        left: (end.left + (end.width / 2) - (bw1 / 2)) + 'px',
        top: (end.top + (end.height / 2) - (bw1 / 2)) + 'px',
        easing: "easeInOutExpo",
        duration: anime.random(400, 950),
      })
      .add({
        targets: bubble,
        opacity: ['0.9', '0.0'],
        easing: 'linear',
        duration: 80,
      }, '-=50').add({
      targets: this.base.querySelector('#uploadsDropdownLinkSplash'),
      borderWidth: ['0rem', '1.2rem'],
      borderColor: [color, color],
      easing: 'easeOutExpo',
      duration: 80
    }, '-=250').add({
      targets: this.base.querySelector('#uploadsDropdownLinkSplash'),
      borderWidth: ['1.2rem', '0rem'],
      borderColor: [color, "#ffffff"],
      duration: 450,
      easing: 'easeInExpo',
      complete: () => {
        bubble.remove();
      }
    });
  }

  render() {
    // language=html
    return html`
      <div class="dropdown">
        <a class="nav-link" href="#" id="uploadsDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Uploads status"
           aria-label="Uploads status" style="position: relative">
          <div id="uploadsDropdownLinkSplash"
               style="position: absolute; left:50%; top: 50%; transform-origin: center center; transform: translateX(-50%) translateY(-50%); height: 0; width: 0; border-radius: 50%; border-style: solid; border-width: 0; color: transparent; opacity: 0.15; "></div>
          <i class="fa fa-upload" style="position: relative; z-index: 2;"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="uploadsDropdownLink" style="width: 37rem;">
          ${window.upload_manager._queue.map((file_upload, i) => html`
            <div key=${i} class="upload-item m-2 border-1 p-2 d-flex flex-wrap">
              <i class="fa fa-file me-2"></i>
                <span class="label me-2">${file_upload.name}</span>
                ${file_upload.progress_percent === 100 ? html`<span class="result ms-auto text-success">upload complete</span>`:''}
                ${file_upload.progress_percent === -1 ? html`<span class="result ms-auto text-danger">upload failed</span>`:''}
                 ${(file_upload.progress_percent >= 1 && file_upload.progress_percent <= 99) ? html`<span class="result ms-auto text-success">${file_upload.progress_percent}%</span>`:''}

              <div class="progress flex-100" style="height: 3px; flex-basis: 100%; ${file_upload.progress_percent === -1 ? 'background-color: var(--bs-danger) !Important;':''}">
                  <div class="progress-bar" role="progressbar" style="width: ${file_upload.progress_percent}%;" aria-valuenow="${file_upload.progress_percent}" aria-valuemin="0"
                       aria-valuemax="100"></div>
                </div>
            </div>`)}
        </div>
      </div>
    `;
  }


}



