// We're using some of the AWS Amplify JS library without the "CLI" that configures all the AWS resources.
// see also: https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#manual-setup-import-storage-bucket
import {Amplify, Storage, AuthStorage, Auth, CognitoRefreshToken, CognitoAccessToken, CognitoIdToken, CognitoUserSession} from '../vendor/aws.min.js';
import FileUploadProgressDropdown from "../components/file_upload_progress_dropdown.js";
import {round} from "../vendor/common.min.js";

/**
 * A globally available manager for user uploads to S3.
 */
export default class UploadManager {
  constructor() {
    this._queue = [];
    this._progress_dropdown = null;
    this.when = new Promise((r) => {
      this._resolve_when = r
    })
    window.auth_manager.when_authed.then(async () => {
      Amplify.configure({
        Auth: {
          identityPoolId: window.auth_manager.application_identity_pool_id, // (required) - Amazon Cognito Identity Pool ID
          region: window.auth_manager.application_user_pool_region, // (required) - Amazon Cognito Region
          userPoolId: window.auth_manager.application_user_pool_id, // (optional) - Amazon Cognito User Pool ID
          userPoolWebClientId: window.auth_manager.application_client_id, // (optional) - Amazon Cognito Web Client ID (App client secret needs to be disabled)
          forceLogin: false
        },
        Storage: {
          AWSS3: {
            bucket: "acceladapt-pro-api-refactor-dev2-enclave", // (required) -  Amazon S3 bucket name
            region: 'us-east-1', // (optional) -  Amazon service region
          }
        }
      });
      await this.update_cognito_credentials();
      window.auth_manager.register_auth_callback(this.update_cognito_credentials.bind(this))

      // try to warn users if they are leaving with uploads still in progress.
      window.addEventListener('beforeunload', () => {
        if (this._queue.find(a => !a.completed && !a.failed)) {
          e.preventDefault();
          return "";
        }
      })

      this._resolve_when();
    });
  }


  async update_cognito_credentials() {
    // force Amplify to use credentials from window.auth_manager.
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({IdToken: window.auth_manager.cognito_tokens.id_token}),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: window.auth_manager.cognito_tokens.refresh_token,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: window.auth_manager.cognito_tokens.access_token,
      }),
    });

    await Auth.Credentials.set(session, 'session');
  }

  async upload_file(file, project_id=null,  expected_layer_id=null,expected_layer_type=null,) {
    if (!this._progress_dropdown){
      this._progress_dropdown = FileUploadProgressDropdown.create();
    }

    const queue_obj = {
      name: file.name,
      started: new Date().toISOString(),
      completed: null,
      failed: null,
      progress_percent: 1,
    }
    this._queue.push(queue_obj)

    await this.when
    let metadata = {}
    if (!project_id){
      project_id =  window.app?.project_stream.getValue()?.project_id
    }
    if (project_id){
      metadata = {metadata: {expc_project_id: project_id}}
    }
    if (expected_layer_type && expected_layer_id){
      if (!project_id){
        throw new Error('Attempted to upload a file for layer processing without an active project!')
      }
      metadata = {metadata: {expc_project_id: project_id, expc_layer_id: expected_layer_id, expc_layer_type: expected_layer_type}}
    }

    let result = null;
    try {
      result = await Storage.put(file.name, file, {
        level: "protected",
        resumable: true,
        identityId: window.auth_manager._user_attributes['sub'],         // user's sub value is provided here, not the identityId
        ...metadata,
        completeCallback: (event) => {
          queue_obj.completed = new Date().toISOString();
          queue_obj.progress_percent = 100;
          this._progress_dropdown.current.set_dirty();
        },
        progressCallback: (progress) => {
          queue_obj.progress_percent = round(progress.loaded / progress.total * 100);
          this._progress_dropdown.current.set_dirty();
        },
        errorCallback: (err) => {
          queue_obj.progress_percent = -1;
          queue_obj.failed = new Date().toISOString();
          console.error('Unexpected error while uploading', err, queue_obj);
          this._progress_dropdown.current.set_dirty();
        }
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
    return result
  }

  get_files() {
    return Storage.list('', {
      level: 'protected',
      identityId: window.auth_manager._user_attributes['sub']
    })
  }


}
