import {BehaviorSubject, withLatestFrom,pairwise} from "../node_modules/acceladapt-online-lib/vendor/rxjs.min.js";
import {get} from '../node_modules/acceladapt-online-lib/vendor/common.min.js';
import AuthManager from "../node_modules/acceladapt-online-lib/managers/auth_manager.js";
import UploadManager from "../node_modules/acceladapt-online-lib/managers/upload_manager.js";
import {NavComponent, setupNavComponent} from './components/nav_component.js';
import RouteComp from "./components/common_components/route_component.js";
import {render} from 'preact';
import {html} from "htm/preact";
import ModifyRoot from './components/modify_root.js';
import IndexRoot from "./components/index_root";


const leaf_bg = new URL('../images/geometric-leaves.png', import.meta.url);

class Project {
  constructor() {
    this.config = null;

    this.when_config = import('/common_config.json')
        .then(data => {
          this.config = data;
        });
  }

  async get_config(key_path, default_value = null) {
    if (!this.config) {
      await this.when_config
    }
    return get(this.config, key_path, default_value);
  }
}


export default class App {

  constructor() {

    this.when_authed = new Promise((resolve) => this.resolve_authed = resolve);
    this.project = new Project();

    this.auth_manager = null;


    this.components = document.getElementsByName("module");
    // this.page_stream = new BehaviorSubject('module_wizard'); // e.g. modules, module_wizard, layer, layer_wizard, dashboard
    // this.subview_stream = new BehaviorSubject('module_type_selection');

    if (typeof window.localStorage.getItem('project_id') === 'undefined') {
      this.page_stream = new BehaviorSubject(''); // e.g. modules, module_wizard, layer, layer_wizard, dashboard
      this.subview_stream = new BehaviorSubject('create_project_component');
    } else {
      this.page_stream = new BehaviorSubject('module_wizard'); // e.g. modules, module_wizard, layer, layer_wizard, dashboard
      this.subview_stream = new BehaviorSubject('module_type_selection');
    }

    const hash_params = window.location.hash.split("/").slice(1);

    //
    // Primary streams based on first-class objects
    //
    /** List of projects the user can access. Emits when initially loaded, and also periodically as new projects are created, or shared with the user. */
    this.user_projects_stream = new BehaviorSubject(null);
    /** The current project the user has selected. Emits once per page-load, and anytime the project properties are updated. */
    this.project_stream = new BehaviorSubject(hash_params && hash_params[0] ? {project_id: hash_params[0]} : null);
    /** Modules for the current project, emits as modules are created or updated. */
    this.modules_stream = new BehaviorSubject(null);
    /** Layers for the current project (including those based on files from other users). */
    this.layers_stream = new BehaviorSubject(null);
    /** All files the user owns, only loaded on demand via fetch_user_files(). */
    this.user_files_stream = new BehaviorSubject([]);
    /** Extra data about the current user (including licensing and profile info) */
    this.user_stream = new BehaviorSubject(null);

    /** The stream of current assets and threats created */
    this.assets_stream = new BehaviorSubject(null);
    this.threats_stream = new BehaviorSubject(null);

    // Derived streams / streams which are only relevant based on the page context.
    /** The currently selected module object */
    this.module_stream = new BehaviorSubject(null);
    /** The currently selected layer object */
    this.layer_stream = new BehaviorSubject(null);
    /** The currently selected module's module_views objects */
    this.module_module_views_stream = new BehaviorSubject(null);
    /** Users the current user knows (e.g. via a mutual project), used for autocompleting on sharing options. */
    this.user_users_stream = new BehaviorSubject(null);

    /** The current module flow that the user is going through */
    this.module_flow_stream = new BehaviorSubject(null);

    /** The current state of the module creation flow.*/
    this.module_flow_state_stream = new BehaviorSubject(0);


    this.user_projects_stream.pipe(withLatestFrom(this.project_stream)).subscribe(([user_projects, project]) => {

      if (user_projects === null) {
        return;
      }
      // redirect the user back to the index page if they haven't selected a project.
      if (!project && window.location.pathname !== "/") {
        window.location = '/';
        return;
      }

      if (project) {
        const project_id = project.project_id
        const new_project = user_projects.find(a => a.project_id === project_id);

        if (new_project || project_id === 'new') {
          // emit the updated project.
          this.project_stream.next(new_project);
        } else {
          // // redirect the user back to the index page if they are no longer part of the project they're viewing.
          // const index = new URL(window.location)
          // index.pathname = ''
          // index.hash = ''
          // index.searchParams.set('e', "project_unavailable")
          // window.location.href = index
        }
      }
    });

    this.init_auth_manager();
    this.init_upload_manager();

    this.auth_manager.when_authed.then(async () => {
      setupNavComponent();
      window.loading_overlay.request_show(2)
      await this.fetch_projects()
      if (window.sessionStorage && window.sessionStorage.getItem('suppress_intro')) {
        window.loading_overlay.request_show(4);
      } else {
        window.loading_overlay.request_show(3);
        if (window.sessionStorage) {
          window.sessionStorage.setItem('suppress_intro', 1)
        }
      }

      /* For the time being, we will try and avoid using dynamic imports, because parcel seems to have
         trouble resolving these imports.
      */

      // the root preact component
      if (new URL(window.location).pathname.startsWith('/modify')) {
        //await import('./components/modify_root.js');
        render(html`<${ModifyRoot}/>`, document.querySelector('body > div.container'));

      } else {

        /* For the time being, we will try and avoid using dynamic imports, because parcel seems to have
           trouble resolving these imports.
        */

        //await import('./components/index_root.js');
        render(html`<${IndexRoot}/>`, document.querySelector('body > div.container'))
      }
    });

    window.extra_greenery = window.localStorage.getItem('extra_greenery') || true;
    if (window.extra_greenery) {
      document.body.style.backgroundImage = `url(${leaf_bg})`
    }
  }

  init_auth_manager() {
    if (!!window.auth_manager) {
      this.auth_manager = window.auth_manager;
    } else {
      window.auth_manager = {has_portal_support: false}
      this.auth_manager = new AuthManager(
          {
            project_id: null,
            application_client_id: 'cvej6no1cjlng7hidvjgnuosd',
            application_user_pool_id: 'us-east-1_Tzr4WlhT2',
            application_url: 'https://dev2-pro.acceladapt.com',
            project_title: null,
            portal_application_id: null,
            portal_client_id: null,
            portal_url: null,
            portal_group: null
          }
      )
      window.auth_manager = this.auth_manager;
    }
  }

  init_upload_manager() {
    if (!window.upload_manager || typeof window.upload_manager === 'undefined') {
        window.upload_manager = new UploadManager();
    }
  }

  async create_project(data) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/projects`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        ...data
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    const success = r.status === 200;

    if(success) {
      this.fetch_projects();
    }

    return {success: success, data: r.status === 403 ? 'Error occurred while creating a new threat.' : await r.json()};
  }

  async add_collaborator(project, collaborator) {
    await (fetch(`${this.auth_manager.application_api_url}/projects`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        project_id: project.project_id,
        collaborators: {
          usernames: {
            add: [collaborator]
          }
        }
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));
  }

  /**
   * Fetch the list of projects. Returns results, but it's best practice to subscribe to window.app.project_stream so that no matter what requested this fetch, your component(s) updates.
   * @param project_id
   * @return {Promise<any>}
   */
  async fetch_projects(project_id = null) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id === null ? "projects" : project_id + "/projects"}`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));
    if (r.status === 200) {
      let data = await r.json();
      this.user_projects_stream.next(data['projects'].map(a => {
        a.user_can_modify = !!data['user_can_modify'].includes(a.project_id);
        return a
      }));

      return data;
    } else {
      this.user_projects_stream.next([]);
      return [];
    }
  }

  /**
   * Fetch the list of modules for the project. Returns results, but it's best practice to subscribe to window.app.modules_stream so that no matter what requested this fetch, your component(s) updates, and to reduce the chance of memory leaks.
   * @param project_id
   * @return {Promise<any>}
   */
  async fetch_modules(project_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/modules`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));
    if (r.status === 200) {
      let data = await r.json();

      this.modules_stream.next(data);
      return data;
    } else {
      this.modules_stream.next({});
      return {};
    }
  }

  async delete_layer(project_id, layer_id,) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/layers`, {
      method: 'DELETE',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        layer_id: layer_id,
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    if (r.status === 200) {
      this.fetch_layers(project_id);
      return true;
    }

    return false;
  }

  async set_layer_intent(project_id, layer_id, layer_intent) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/layers`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        layer_id: layer_id,
        layer_intent: layer_intent
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    if (r.status === 200) {
      this.fetch_layers(project_id);
      return true;
    }

    return false;
  }

  /**
   * Fetch the list of layers. Returns results, but it's best practice to subscribe to window.app.layers_stream so that no matter what requested this fetch, your component(s) updates.
   * @param project_id
   * @return {Promise<any>}
   */
  async fetch_layers(project_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/layers`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    if (r.status === 200) {
      let data = await r.json();

      this.layers_stream.next(data);
      return data;
    } else {
      this.layers_stream.next([]);
      return [];
    }
  }

  async fetch_layers_status(project_id, layer_ids) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/layers/status`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        layer_ids
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    return r;
  }

  async invite_user(project_id, email) {


  }

  async list_pending_invites(project_id) {


  }

  /**
   * Create an Asset that can be used to create a module in the Module section.
   * @param project_id
   * @param data: {asset_id, project_id, layer, label, abbreviation, tags, high_potential_impact_tags, commentary}
   * @returns {Promise<{data: any, success: boolean}>}
   */
  async create_asset(project_id, data) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/assets`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        asset_id: data.asset_id,
        project_id: data.project_id,
        layer: data.layer,
        label: data.label,
        abbreviation: data.abbreviation,
        tags: data.tags,
        high_potential_impact_tags: data.high_potential_impact_tags,
        commentary: data.commentary
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    const success = r.status === 200;

    if(success) {
      this.fetch_assets(project_id);
    }

    return {success: success, data: r.status === 403 ? 'Error occurred while creating a new asset.' : await r.json()};
  }

  /**
   * Delete a asset, if successful it will re-fetch the assets.
   * @param project_id
   * @param asset_id
   * @returns {Promise<boolean>}
   */
  async delete_asset(project_id, asset_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/assets`, {
      method: 'DELETE',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        asset_id: asset_id
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    const success = r.status === 200;

    if(success) {
      this.fetch_assets(project_id);
    }

    return success;
  }

  /**
   * Get all of the assets associated to a project_id.
   * @param project_id
   * @param layer_id
   * @returns {Promise<any>}
   */
  async fetch_assets(project_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/assets`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    if (r.status === 200) {
      let data = await r.json();
      this.assets_stream.next(data);
      return data;
    }

    this.assets_stream.next([]);
    return [];
  }

  /**
   * Create a new Threat for a project.
   * @param project_id
   * @param data
   * @returns {Promise<{data: any, success: boolean}>}
   */
  async create_threat(project_id, data) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/threats`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        ...data
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    const success = r.status === 200;

    if(success) {
      this.fetch_threats(project_id);
    }

    return {success: success, data: r.status === 403 ? 'Error occurred while creating a new threat.' : await r.json()};
  }

  /**
   * Delete a threat, if successful it will re-fetch the threats.
   * @param project_id
   * @param threat_id
   * @returns {Promise<boolean>}
   */
  async delete_threat(project_id, threat_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/threats`, {
      method: 'DELETE',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        threat_id: threat_id
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    const success = r.status === 200;

    if(success) {
      this.fetch_threats(project_id);
    }

    return success;
  }


  /**
   * Get all of the threats associated to a project_id.
   * @param project_id
   * @param layer_id
   * @returns {Promise<any>}
   */
  async fetch_threats(project_id) {

    const r = await (fetch(`${this.auth_manager.application_api_url}/${project_id}/threats`, {
      method: 'POST',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token
      }),
      cors: true
    }).catch(AuthManager._access_denied_response));

    if (r.status === 200) {
      let data = await r.json();
      this.threats_stream.next(data);
      return data;
    }

    this.threats_stream.next([]);
    return [];
  }

  async request_show_file_management_dialog() {
    if (!this.file_management_dialog) {
      // this.file_management_dialog = new ((await import('./components/file_management_dialog.js'))['default'])
      document.body.appendChild(this.file_management_dialog);
    }
    return this.file_management_dialog.request_show();
  }
}

if (typeof window.app === 'undefined') {
  window.app = new App();
}
