import {createRef, useContext, useEffect, useState} from "preact/compat";
import ModulesList from "../modify_flow/modules_list";
import ModuleTypeSelection from "./module_type_selection";
import {html} from "htm/preact";
import ModuleInfo from "../modify_flow/module_info";

const ModuleFlow = ({project_id}) => {

  /*
  We will use a series of numbers to represent which components to render at any given time.
  0: ModulesList
  1: ModuleTypeSelection
  2: ModuleInfo
   */

  const [showState, setShowState] = useState(-1);

  useEffect(() => {

    const subscription = window.app.module_flow_state_stream.subscribe((data) => {
      setShowState(data);
    });

    return () => {
      subscription.unsubscribe()
    }

  }, []);

  const renderComponent = () => {

    switch(showState) {
      case -1:
        return html`<h4>Loading...</h4>`
      case 0:
        return html`<${ModulesList} project_id=${project_id} //>`;
      case 1:
        return html`<${ModuleInfo} project_id=${project_id}></${ModuleInfo}>`;
      case 2:
        return html`<div>something</div>`;
    }

  }

  return html`
  <div>
      ${renderComponent()}
  </div>
  `
}

export default ModuleFlow;