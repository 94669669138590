import {createRef} from "preact";
import {useEffect} from "preact/compat";
import {Tooltip} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import {html} from "htm/preact";
import UserLink from "./user_link.js";

const default_cover = new URL('/protected/media/ryan-mercier-3U7tnALnvas-unsplash.png', import.meta.url);

const ProjectCard = ({project}) => {

	const tooltipRef = createRef();

	useEffect(() => {

		new Tooltip(tooltipRef.current);

	}, []);

	return html`
		<div class="aap-card project-card d-flex flex-column justify-content-between border p-2 h-100">
			<div>
				<div>
					<img class="img-fluid" src=${(project?.cover_image_url || default_cover.href).replace('{project_id}',project.project_id)} alt="create project banner" data-bs-toggle="tooltip" ref=${tooltipRef} title="${project?.cover_image_attribution}"/>
				</div>
				<div class="d-flex justify-content-between align-items-center mt-2 mb-4">
					<div class="project-title">${project.title || project.project_id}</div>
					<div class="project-date">created ${project.created?.slice(0,10) || "?"} by ${project.created_by ? html`<${UserLink} user_id=${project.created_by}/>`:'?'}</div>
				</div>
			</div>

			<div class="d-flex justify-content-end mt-2">
			
				<a class="btn btn-outline-primary-aap me-2" href="view.html#/${project.project_id}/">View Project</a>
        <a className="btn btn-secondary" href="modify.html#/${project.project_id}/layers">Modify</a>
			</div>
		</div>
	`;
}



export default ProjectCard;
