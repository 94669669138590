import {createRef, useEffect, useState} from "preact/compat";

import {Tooltip, Modal} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";

import {html} from "htm/preact";
import {route} from "preact-router";
import {shortenName} from "../../utils";

const AssetInfo = ({project_id, module_id}) => {

  const globalRef = createRef();

  const [assetLabel, setAssetLabel] = useState("");
  const [assetAbbreviation, setAssetAbbreviation] = useState("");
  const [assetId, setAssetId] = useState("");
  const [assetDesc, setAssetDesc] = useState("");
  const [tags, setTags] = useState("");
  const [highPotentialTags, setHighPotentialTags] = useState("");

  const [assets, setAssets] = useState(null);
  const [layers, setLayers] = useState(null);

  const [selectedLayers, setSelectedLayers] = useState([]);

  const [showMessage, setShowMessage] = useState({show: false, message: '', type: 'success'});
  const [creating, setCreating] = useState(false);

  const [deleteAsset, setDeleteAsset] = useState(null);

  /*
  Create an asset that is stored locally for the time being. Once created, push to asset_stream.
   */
  const handleCreateAsset = async () => {

    if (assetLabel.length === 0 || assetId.length === 0 || assetDesc.length === 0 || assetAbbreviation.length === 0) {
      setShowMessage({show: true, message: "Please fill out all of the required fields indicated by a *.", type: 'warning'});
      return;
    }

    /* If the list of assets is still null then we don't have any assets created, otherwise search and see if we have an asset with the same
       asset_id, if we do then return true, otherwise return false */
    const assetExists = assets === null ? false : (typeof assets.find(a => a.asset_id === assetId) === 'undefined' ? false : true);

    if (assetExists) {
      setShowMessage({show: true, message: `A asset with the id ${assetId} already exists.`, type: 'warning'});
      return;
    }

    const asset_obj = {
      asset_id: assetId,
      label: assetLabel,
      commentary: assetDesc,
      abbreviation: assetAbbreviation.length > 3 ? assetAbbreviation.slice(0, 3).toUpperCase() : assetAbbreviation.toUpperCase(),
      layer: selectedLayers.length > 0 ? selectedLayers.map(l => l.layer_id) : [],
      tags: handleTags(tags),
      high_potential_impact_tags: handleTags(highPotentialTags)
    }

    setCreating(true);

    const data = await window.app.create_asset(project_id, asset_obj);

    setShowMessage({show: true, message: data.success ? 'Successfully created a asset.' : data.data, type: data.success ? 'success' : 'error'});
    setCreating(false);

  }

  const handleReset = () => {
    setAssetLabel("");
    setAssetId("");
    setAssetDesc("");
    setAssetAbbreviation("");
    setTags("");
    setHighPotentialTags("");
    setSelectedLayers([]);
  }

  const handleRemoveSelectedLayer = (layer) => {
    let clone = [...selectedLayers];
    clone = clone.filter(l => l.layer_id !== layer.layer_id);
    setSelectedLayers(clone);
  }

  const setToolTip = (title, message) => {
    return `
        <div>
            <h6>${title}</h6>
            <p>
            ${message}
            </p>
        </div>
          `
  }

  const handleTags = (data) => {

    if(data.length === 0) return [];

    let _tags = data.split(',');

    _tags = _tags.map(v => v.trim()).filter(v => v.length > 0);

    return _tags;
  }

  const doesTagExist = (tag) => {

    if(typeof selectedLayers === 'undefined' || selectedLayers.length === 0) return false;

    for(const l of selectedLayers) {
      if(!l.hasOwnProperty('tag_queries')) continue;

      if(Object.keys(l.tag_queries).includes(tag)) return true;
    }

    return false;
  }

  const handleDeleteAsset = async (id) => {

    if(id === null) return;

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-asset-modal'));
    modal.hide();

    const success = await window.app.delete_asset(project_id, id);

    if(success) {
      setShowMessage({show: true, message: 'Successfully deleted an asset.', type: 'success'});
    }
  }

  useEffect(() => {

    globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));

    if (project_id) {

      window.app.fetch_layers(project_id);
      window.app.fetch_assets(project_id);

      const assets_stream = window.app.assets_stream.subscribe((data) => {
        setAssets(data);
      });

      const layers_stream = window.app.layers_stream.subscribe((data) => {
        if (data !== null) {
          setLayers(data.filter(l => l.layer_type === 'vector_polygon'));
        }
      });

      return () => {
        assets_stream.unsubscribe();
        layers_stream.unsubscribe();
      };
    }

  }, []);

  useEffect(() => {

    if(showMessage.show) {
      document.getElementById('status-messages').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }, [showMessage]);

  useEffect(() => {

    if(assets !== null && assets.length > 0) {

      // We don't want the tooltip to be registered again if it already exists.
      globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => {
        if(Tooltip.getInstance(element) === null) {
          new Tooltip(element)
        }
      });

    }

  }, [assets]);

  useEffect(() => {

    if(deleteAsset === null) return;

    console.log(deleteAsset);

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-asset-modal'));
    modal.show();

  }, [deleteAsset]);

  return html`
    <div ref=${globalRef}>
      <div class="d-flex aap-shadow bg-gray p-3 mb-3">
        <div class="d-flex flex-column flex-grow-1 justify-content-between" style="min-height: 30rem">
 
          <div class="d-flex-lg-up">
            
            <div class="w-50-lg-up">
              
              <div id="status-messages" class=${`${showMessage.type === 'success' ? 'success-message' : showMessage.type === 'warning' ? 'warning-message' : 'error-message'} mb-3 ${showMessage.show ? '' : 'd-none'}`} onclick=${() => {setShowMessage({show: false, message: '', type: 'error'})}}>
                <div></div>
                ${showMessage.message}
              </div>
              
              <div class="d-flex-md-up mb-3 w-100">
                <div>
                  <div class="d-flex justify-content-between">
                    <label htmlFor="asset-label" class="form-label">Asset Label</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title=${setToolTip('Asset Label', 'This input field is for setting the Asset Label for your project.')}>
                                            <i class="fa-light fa-circle-question "/>
                                          </span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control aap-input" id="asset-label" placeholder="Asset Label" onchange=${(e) => {
                      setAssetLabel(e.target.value)
                    }} value=${assetLabel}/>
                  </div>
                </div>
  
                <div class="ms-md-up-2 me-md-up-2">
                  <div class="d-flex justify-content-between">
                    <label htmlFor="asset-id" class="form-label">Asset ID</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right" title=${setToolTip('Asset ID', 'This input field is for setting the Asset ID for your project.')}><i
                        class="fa-light fa-circle-question"/></span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control aap-input" id="asset-id" placeholder="Asset ID" onchange=${(e) => {
                      setAssetId(e.target.value)
                    }} value=${assetId}/>
                  </div>
                </div>
  
                <div>
                  <div class="d-flex justify-content-between">
                    <label htmlFor="asset-abbreviation" class="form-label">Asset Abbreviation</label>
                    <span data-bs-toggle="tooltip" data-bs-html="true"
                          data-bs-placement="right" title=${setToolTip('Asset Abbreviation', '1-3 character abbreviation of id to be used for auto-generating abbreviated column names for final output files in AAE.')}><i
                        class="fa-light fa-circle-question"/></span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control aap-input" id="asset-abbreviation" placeholder="Asset Abbreviation" onchange=${(e) => {
                      setAssetAbbreviation(e.target.value)
                    }} value=${assetAbbreviation}/>
                  </div>
                </div>
              </div>
  
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <label htmlFor="description" class="form-label">Asset Description</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('Asset Description', 'This input field is for setting the Asset Description for your project.')}><i
                      class="fa-light fa-circle-question"/></span>
                </div>
                <textarea class="form-control" id="asset-description" rows="8" onchange=${(e) => {
                  setAssetDesc(e.target.value)
                }} value=${assetDesc} />
              </div>
  
              <hr/>
  
              <div class="mb-3">
                <label htmlFor="module-type" class="form-label">Layers</label>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    Layers
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                    ${
                        layers === null ? ''
                            :
                            layers.map((layer, index) => html`
                              <li key=${index} class="dropdown-item d-flex justify-content-between" onclick=${() => {
                                setSelectedLayers([...new Set([...selectedLayers, layer])])
                              }}>
                                <span class="me-2">${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>
                                <span data-bs-toggle="tooltip" data-bs-html="true"
                                      data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file}>
                                        <i class="fa-light fa-circle-question "/>
                                      </span>
                              </li>
                            `)
                    }
                  </ul>
                </div>
  
                <div class="mt-2 d-flex flex-wrap">
                  ${
                      typeof selectedLayers !== 'undefined' && selectedLayers.length > 0 ?
                          selectedLayers.map((layer, index) => (
  
                              html`<span class="selected-layer me-2" onclick=${() => {
                                handleRemoveSelectedLayer(layer)
                              }}>${layer !== null && typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file.split('/').slice(-1)[0]}</span>`
  
                          ))
  
                          :
                          ''
                  }
                </div>
  
              </div>
  
              <div class="mb-3 w-50-md-up">
                <div class="d-flex justify-content-between">
                  <label htmlFor="asset-tags" class="form-label">Asset Tags</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('Asset Tags', 'Comma separated list of asset tags to include in the asset category.')}><i
                      class="fa-light fa-circle-question"/></span>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control aap-input" id="asset-tags" placeholder="Asset Tags" onchange=${(e) => {
                    setTags(e.target.value)
                  }} value=${tags}/>
                </div>
  
                <div class="d-flex flex-wrap">
                  ${handleTags(tags).length > 0 ?
                      handleTags(tags).map(tag => (
                          html`
                                  <div style="background-color: ${doesTagExist(tag) ? '#3bb482' : 'red'}; color: white;" class="me-1 mt-2">
                                    <span className="p-1 m-1">${tag}</span>
                                  </div>
                                `
                      ))
                      :
                      ''
                  }
                </div>
                
              </div>
  
              <div class="mb-3 w-50-md-up">
                <div class="d-flex justify-content-between">
                  <label htmlFor="hpi-asset-tags" class="form-label">High Potential Impact Asset Tags</label>
                  <span data-bs-toggle="tooltip" data-bs-html="true"
                        data-bs-placement="right" title=${setToolTip('High Potential Impact Asset Tags ', 'Comma separated list of asset tags to include in the high_potential_impact category during AAE assessment.')}><i
                      class="fa-light fa-circle-question"/></span>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control aap-input" id="hpi-asset-tags" placeholder="High Potential Impact Asset Tags" onchange=${(e) => {
                    setHighPotentialTags(e.target.value)
                  }} value=${highPotentialTags}/>
                </div>
  
                <div class="d-flex flex-wrap">
                  ${handleTags(highPotentialTags).length > 0 ?
                      handleTags(highPotentialTags).map(tag => (
                          html`
                                  <div style="background-color: ${doesTagExist(tag) ? '#3bb482' : 'red'}; color: white;" class="me-1 mt-2">
                                    <span className="p-1 m-1">${tag}</span>
                                  </div>
                                `
                      ))
                      :
                      ''
                  }
                </div>
                
              </div>

              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary me-2" onclick=${handleReset}>Reset</button>
                <button class=${`btn btn-outline-primary-aap ps-4 pe-4 ${creating ? 'disable-btn' : ''}`} onclick=${handleCreateAsset}>${creating ? html`Loading <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i>` : 'Create Asset'}</button>
              </div>
              
            </div>
            
            <div class="w-50-lg-up left-border-lg-up top-border-lg-down ms-lg-up-3 ps-lg-up-3 mt-lg-down-3 pt-lg-down-3" style="text-align: center;">
              ${
    
                assets === null ? html`<div>Checking for assets <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i></div>` :
                    
                    assets.length > 0 ? 
                      
                      html`
                      <div class="fw-bold mb-3">Configured Assets</div>
                      ${
                          assets.map(asset => (
                              html`
                                <div class="d-flex py-2 border-bottom">
                                  <div class="d-flex align-items-center justify-content-between me-auto">
                                    <i class="me-3 fa-light fa-circle-info" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title=${setToolTip(`ID: ${asset.asset_id}`, `Commentary: ${asset.commentary}`)}></i><span>${asset.label}</span>
                                  </div>
                                  <div class="d-flex align-items-center justify-content-end delete-asset-threat" onclick=${() => {setDeleteAsset(Object.assign({}, asset))}}>
                                    <i class="fa-light fa-trash-can"></i>
                                  </div>
                                </div>
                                `
                            ))
                    
                      }
                      `
                       :
                      html`<div>No Assets have been configured!</div>`
              }
            </div>
            
          </div>
        </div>

      </div>
      <div id="delete-asset-modal" class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${deleteAsset !== null ? deleteAsset.label : 'Title'}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete the asset with ID ${deleteAsset !== null ? html`<span style="color: #e74949; font-weight: bold;">${deleteAsset.asset_id}</span>` : 'id'}?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-outline-error" onclick=${() => {handleDeleteAsset(deleteAsset !== null ? deleteAsset.asset_id : null)}} >Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  `;
}


export default AssetInfo;
