import {render} from 'htm/preact';
import {html} from 'htm/preact';
import {route} from "preact-router";
import {createRef, useEffect, useState} from "preact/compat";
import {Modal} from "../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";

const logo = new URL('/node_modules/acceladapt-online-lib/assets/AccelAdaptLogoHoriz.png', import.meta.url);

function getDate(inspected_date) {
  const date = new Date(inspected_date);
  return date.toLocaleString();
}

function getSize(size) {
  let kb = size / 1000;
  let mb = kb / 1000;

  return mb >= 1 ? Number.parseFloat(`${mb}`).toFixed(2) + ' MB' : Number.parseFloat(`${kb}`).toFixed(2) + ' KB';
}

const NavComponent = () => {

  const globalRef = createRef();

  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState(null);

  const [loadingFiles, setLoadingFiles] = useState(true);
  const [files, setFiles] = useState([]);

  useEffect(() => {

    window.app.fetch_projects();

    const project_stream = window.app.project_stream.subscribe((data) => {
      setProject(data);
    });

    const projects_stream = window.app.user_projects_stream.subscribe((data) => {
      setProjects(data);
    });

    return () => {
      project_stream.unsubscribe();
      projects_stream.unsubscribe();
    }

  }, []);

  const handleRouteChange = (p) => {

    // we can't use the build in route() function from preact, because the navigation bar is not part of any Router. It will not work.
    //route('/my/url');
    window.location = `modify.html#/${p.project_id}/layers`;
  }

  const handleMyFiles = () => {

    setLoadingFiles(true);

    window.upload_manager.get_files().then((data) => {
      setLoadingFiles(false);
      setFiles(data.filter(d => !d.key.includes('layers/')));
    });

    const modal = Modal.getOrCreateInstance(document.getElementById('my-files-modal'));
    modal.show();
  }

  useEffect(() => {

    if (project !== null && projects !== null) {

      const _p = projects.find(p => p.project_id === project.project_id);

      if (typeof _p !== 'undefined') {
        setProject(_p);
      }

    }


  }, [project, projects]);

  return html`

    <nav ref=${globalRef} class="navbar navbar-light navbar-expand-md bg-light d-flex justify-content-end mb-4 border-bottom fixed-top aap-shadow" style="border-bottom-color: #50BE97 !important">

      <div class="projects-dropdown me-auto">
        <ul id="breadcrumbs" class="breadcrumb mb-0 me-auto breadcrumb-light">

          <li class="nav-item dropdown">
            <button class="btn btn-light dropdown-toggle" href="#" id="projects-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="me-2 fa-light fa-location-dot"></i>
              <span class="me-2 d-none-md">
                  ${project === null || typeof project === 'undefined' ?
                      'My Projects' :
                      project.hasOwnProperty('title') ? project.title.length > 42 ? `${project.title.slice(0, 40)}...` : project.title
                          : 'Project'
                  }
              </span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="projects-dropdown">
              ${projects !== null ?

                  projects.map((p) => (
                      html`
                        <li onclick=${() => {
                        handleRouteChange(p)
                      }}><span class="dropdown-item ${project !== null && project.project_id === p.project_id ? 'dropdown-item-selected' : ''}">${p.title.length > 42 ? `${p.title.slice(0, 40)}...` : p.title}</span>
                        </li>
                      `
                  ))
                  :
                  ''
              }
              <li class="dropdown-divider"></li>
              <li>
                <a className="dropdown-item d-flex justify-content-start align-items-center" href="/">
                  <i class="fa-light fa-house me-2"></i>
                  <span>Home</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

      </div>
      
      <a class="navbar-brand logo d-block" target="_blank" href="https://acceladapt.com/">
        <img src="${logo}" alt="AccelAdapt Pro" title="AccelAdapt Pro" data-bs-content="A community resilience assessment product by FernLeaf Interactive" data-bs-placement="bottom" data-bs-toggle="popover" data-bs-container="body"/>
      </a>
      
      <div class="upload-progress d-none"></div>
      
      <div class="dropdown">
        <button class="btn btn-link p-0" style="box-shadow: none; color:black;" type="button" id="optionsMenuLink" data-bs-toggle="dropdown">
          <i class="fa fa-cog"></i>
        </button>
        <div id="optionsMenuDropdown" class="dropdown-menu dropdown-menu-end options-menu" aria-labelledby="optionsMenuLink">
          <button class="dropdown-item" type="button" onclick=${() => window.upload_manager.request_show_add_files_dialog()}><i class='fa fa-upload fa-small me-2'></i>Add Files</button>
          <button class="dropdown-item" type="button" onclick=${() => handleMyFiles()}><i class='fa fa-folder-user fa-small me-2'></i>My Files</button>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item btn-outline-light" href="/"><i class="me-2 fa-light fa-location-dot"></i>My Projects</a>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item btn-outline-light logout-button" onclick=${() => {
            window.auth_manager && window.auth_manager.logout()
          }}><i class='fa fa-sign-out fa-small me-2'></i>Logout
          </button>
        </div>
      </div>
    </nav>

    <div id="my-files-modal" class="modal" tabindex="-1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">My Files ${files.length > 0 ? `(${files.length})` : ''}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body overflow-auto">

            ${loadingFiles ? html`Loading files <i className="ms-2 rotate-icon fa-light fa-spinner-third"></i>`
                :
                html`
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">File Name</th>
                      <th scope="col">Last Modified</th>
                      <th scope="col">Size</th>
                    </tr>
                    </thead>
                    <tbody>

                    ${
                        files.map((f => (
                            html`
                              <tr>
                                <td>${f.key}</td>
                                <td>${getDate(f.lastModified)}</td>
                                <td>${getSize(f.size)}</td>
                              </tr>

                            `
                        )))
                    }
                    </tbody>
                  </table>

                `
            }

          </div>
        </div>
      </div>
    </div>

  `
}

const setupNavComponent = () => {
  const el = document.getElementById('nav');
  render(html`
    <${NavComponent}/>`, el);
}

export {NavComponent, setupNavComponent};