let _element_i = 0;

/**
 * Provides a quick way to generate unique ids for dom elements using a simple counter and optional prefix.
 * @param prefix
 * @returns {*}
 */
export function next_element_id(prefix = null) {
    return (prefix || '') + (_element_i++)
}

/**
 * Hides the primary app loading overlay.
 */
export function hide_loading_overlay() {
  document.querySelector('.loading-overlay').classList.add('hidden');
  setTimeout(() => {
    document.querySelector('.loading-overlay').style.display = 'none';
  }, 1500)
}

export function formatLayerFilename(file) {
  // s3://acceladapt-pro-enclave-dev/protected/2816be83-1529-408f-9516-8983c76907e7/Buncombe_County_Boundary.zip
  if (!file) {
    return ''
  }
  file = file.split('/').slice(-1)[0]
  return file;
}

export function layer_filename(file) {
  // s3://acceladapt-pro-enclave-dev/protected/2816be83-1529-408f-9516-8983c76907e7/Buncombe_County_Boundary.zip
  if (!file) {
    return ''
  }
  file = file.split('/').slice(-1)[0]
  return file;
}

export function shortenName(str, maxSize) {
  return str.length > maxSize ? str.slice(0, maxSize - 3) + '...' : str;
}