import { useEffect, useState } from "preact/compat";
import { FlowNavTopContext } from "../flow_context.js";
import FlowNavTop from "../common_components/flow_nav_top.js";

import {html} from "htm/preact";

const Review = () => {

    const [FlowNavTopState, setFlowNavTopState] = useState(4);

    const handleNext = () => {
        window.location.href = "/datasets/";
    }

    const handlePrevious = () => {
        window.location.href = "/threat-info/";
    }

    useEffect(() => {
    }, []);

    return html`
        <${FlowNavTopContext.Provider} value=${FlowNavTopState}>

            <div class="container">
                <${FlowNavTop} />

                <div class="d-flex aap-shadow p-3 mt-3 mb-3">

                    <div class="d-flex flex-column justify-content-between w-100" style="min-height: 30rem">

                        <div class="d-flex mb-3 p-3 w-100 border" style="text-align: center;">

                            <div class="w-25">
                                <div>
                                    <span style="font-weight: bold;">Layer Checks</span>
                                </div>
                            </div>

                            <div class="w-100">

                                <div class="d-flex align-items-center border-bottom w-100 mb-3 pb-2">
                                    <di class="w-50">
                                        <span style="font-weight: bold">Layer</span>
                                    </di>
                                    <di class="w-25">
                                        <span style="font-weight: bold">Status</span>
                                    </di>
                                </div>

                                <div class="d-flex align-items-center w-100 mb-3">
                                    <di class="w-50">
                                        <span>Parcels Layer</span>
                                    </di>
                                    <di class="w-25">
                                        <span class="status-ready">Ready</span>
                                    </di>
                                    <di class="w-25" style="text-align: right">
                                        <button class="btn btn-secondary">Modify</button>
                                    </di>
                                </div>

                                <div class="d-flex align-items-center w-100">
                                    <di class="w-50">
                                        <span>Flood Layer</span>
                                    </di>
                                    <di class="w-25">
                                        <span class="status-pending">Pending</span>
                                    </di>
                                    <di class="w-25" style="text-align: right">
<!--                                        {/*<button class={"btn btn-secondary"}>Modify</button>*/}-->
                                    </di>
                                </div>

                            </div>



                        </div>

                        <div class="d-flex mb-3 p-3 w-100 border" style="text-align: center">

                            <div class="w-25">
                                <span style="font-weight: bold">Asset Tagging Check</span>
                            </div>

                            <div class="w-100">

                                <div class="d-flex align-items-center border-bottom w-100 mb-3 pb-2">
                                    <di class="w-50">
                                        <span style="font-weight: bold">Layer</span>
                                    </di>
                                    <di class="w-25">
                                        <span style="font-weight: bold">Status</span>
                                    </di>
                                </div>

                                <div class="d-flex align-items-center w-100 mb-3">
                                    <di class="w-50">
                                        <span>Residential</span>
                                    </di>
                                    <di class="w-25">
                                        <span class="status-error">Error</span>
                                    </di>
                                    <di class="w-25" style="text-align: right">
<!--                                        {/*<button class="btn btn-secondary">Modify</button>*/}-->
                                    </di>
                                </div>

                                <div class="d-flex align-items-center w-100">
                                    <di class="w-50">
                                        <span>Commercial</span>
                                    </di>
                                    <di class="w-25">
                                        <span class="status-ready">Ready</span>
                                    </di>
                                    <di class="w-25" style= style="text-align: right">
                                        <button class="btn btn-secondary">Modify</button>
                                    </di>
                                </div>

                            </div>


                        </div>


                        <div class="d-flex justify-content-end mt-auto">
                            <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous</button>
                            <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${handleNext}>Next</button>
                        </div>
                    </div>

                </div>
            </div>


        </FlowNavTopContext.Provider>

    `;
}

export default Review;
