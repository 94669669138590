import { useEffect, useState } from "preact/compat";
import { FlowNavTopContext } from "../flow_context.js";

import FlowNavTop from "../common_components/flow_nav_top.js";

import {html} from "htm/preact";
import {route} from "preact-router";

const AssessmentRules = ({project_id, module_id}) => {

    const [FlowNavTopState, setFlowNavTopState] = useState(3);

    const [bfe, setBfe] = useState("");
    const [bfeExtra, setBfeExtra] = useState("");

    const [currentState, setCurrentState] = useState(null);

    const handleNext = () => {
      route(`/${project_id}/m/${module_id}/review/`);
    }

    const handlePrevious = () => {
      route(`/${project_id}/m/${module_id}/asset-tags/`);
    }

    useEffect(() => {

      if(project_id && module_id) {

        const subscription = window.app.module_flow_stream.subscribe((data) => {
          setCurrentState(data);

          if(data !== null) {

            if(!data.hasOwnProperty('bfe') || !data.hasOwnProperty('bfe_extra')) return;

            setBfe(data.bfe);
            setBfeExtra(data.bfe_extra);

          }

        });

        return () => {
          subscription.unsubscribe()
        };
      }

    }, []);

    return html`
        <${FlowNavTopContext.Provider} value=${FlowNavTopState}>

            <div class="container">
                <${FlowNavTop} />

                <div class="d-flex aap-shadow p-3 mt-3 mb-3">

                    <div class="d-flex flex-column justify-content-between ms-5 w-75" style="min-height: 30rem;">
                        <div class="d-flex flex-column justify-content-between mb-3 w-100">
                            <div class="d-flex align-items-center mb-4">
                                <div class="w-50">
                                    <label htmlFor="bfe" class="form-label">Year of first BFE requirement</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control aap-input me-5" id="bfe" placeholder="BFE" onchange=${(e) => {setBfe(e.target.value)}} value=${bfe} />
                                    </div>
                                </div>

                                <div class="w-50">
                                    <span style="color: #aea9a9">The first year of FEMA NFIP participation or when the first Base Flood Elevation (BFE) building requirement went into effect.</span>
                                </div>

                            </div>

                            <div class="d-flex align-items-center mb-4">
                                <div class="w-50">
                                    <label htmlFor="bfe-extra" class="form-label">Year of BFE + 1ft requirement</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control aap-input me-5" id="bfe-extra" placeholder="BFE + 1ft" onchange=${(e) => {setBfeExtra(e.target.value)}} value=${bfeExtra} />
                                    </div>
                                </div>


                                <div class="w-50">
                                    <span style="color: #aea9a9">The year of the first building requirement to exceed the Base Flood Elevation by 1ft or more. Leave blank if no such requirement exists.</span>
                                </div>

                            </div>

                        </div>
                        <div class="d-flex justify-content-end mt-auto">
                            <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous
                            </button>
                            <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${handleNext}>Next</button>
                        </div>
                    </div>

                </div>
            </div>


        <//>

    `;
}



export default AssessmentRules;
