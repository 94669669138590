import {useContext} from "preact/compat";
import {FlowNavSideContext} from "../flow_context.js";
import {html} from "htm/preact";

function FlowNavSide() {

    const context = useContext(FlowNavSideContext);

    return html`
        <div class="flow-side pe-5">
            ${
                (typeof context !== 'undefined' && context.values.length > 0) ?
                context.values.map((v, index) => html`
                    <div key=${Math.random()} class="ps-3 pe-3 pt-1 pb-1 mb-3 w-100 flow-item ${context.current === index ? "flow-side-active" : ""}">
                        ${v}
                    </div>
                `) : ''
            }
        </div>
    `
}

export default FlowNavSide;