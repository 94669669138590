[
  [
    "6351",
    "NAD83(2011) / EPSG Arctic zone 5-29"
  ],
  [
    "6352",
    "NAD83(2011) / EPSG Arctic zone 5-31"
  ],
  [
    "6353",
    "NAD83(2011) / EPSG Arctic zone 6-14"
  ],
  [
    "6354",
    "NAD83(2011) / EPSG Arctic zone 6-16"
  ],
  [
    "6403",
    "NAD83(2011) / Alaska zone 10"
  ],
  [
    "6410",
    "NAD83(2011) / Arkansas North"
  ],
  [
    "6412",
    "NAD83(2011) / Arkansas South"
  ],
  [
    "6415",
    "NAD83(2011) / California zone 1"
  ],
  [
    "6417",
    "NAD83(2011) / California zone 2"
  ],
  [
    "6419",
    "NAD83(2011) / California zone 3"
  ],
  [
    "6421",
    "NAD83(2011) / California zone 4"
  ],
  [
    "6423",
    "NAD83(2011) / California zone 5"
  ],
  [
    "6425",
    "NAD83(2011) / California zone 6"
  ],
  [
    "6427",
    "NAD83(2011) / Colorado Central"
  ],
  [
    "6429",
    "NAD83(2011) / Colorado North"
  ],
  [
    "6431",
    "NAD83(2011) / Colorado South"
  ],
  [
    "6433",
    "NAD83(2011) / Connecticut"
  ],
  [
    "6440",
    "NAD83(2011) / Florida North"
  ],
  [
    "6462",
    "NAD83(2011) / Iowa North"
  ],
  [
    "6464",
    "NAD83(2011) / Iowa South"
  ],
  [
    "6466",
    "NAD83(2011) / Kansas North"
  ],
  [
    "6468",
    "NAD83(2011) / Kansas South"
  ],
  [
    "6470",
    "NAD83(2011) / Kentucky North"
  ],
  [
    "6472",
    "NAD83(2011) / Kentucky Single Zone"
  ],
  [
    "6474",
    "NAD83(2011) / Kentucky South"
  ],
  [
    "6476",
    "NAD83(2011) / Louisiana North"
  ],
  [
    "6478",
    "NAD83(2011) / Louisiana South"
  ],
  [
    "6487",
    "NAD83(2011) / Maryland"
  ],
  [
    "6489",
    "NAD83(2011) / Massachusetts Island"
  ],
  [
    "6491",
    "NAD83(2011) / Massachusetts Mainland"
  ],
  [
    "6493",
    "NAD83(2011) / Michigan Central"
  ],
  [
    "6495",
    "NAD83(2011) / Michigan North"
  ],
  [
    "6498",
    "NAD83(2011) / Michigan South"
  ],
  [
    "6500",
    "NAD83(2011) / Minnesota Central"
  ],
  [
    "6502",
    "NAD83(2011) / Minnesota North"
  ],
  [
    "6504",
    "NAD83(2011) / Minnesota South"
  ],
  [
    "6514",
    "NAD83(2011) / Montana"
  ],
  [
    "6516",
    "NAD83(2011) / Nebraska"
  ],
  [
    "6538",
    "NAD83(2011) / New York Long Island"
  ],
  [
    "6542",
    "NAD83(2011) / North Carolina"
  ],
  [
    "6544",
    "NAD83(2011) / North Dakota North"
  ],
  [
    "6546",
    "NAD83(2011) / North Dakota South"
  ],
  [
    "6548",
    "NAD83(2011) / Ohio North"
  ],
  [
    "6550",
    "NAD83(2011) / Ohio South"
  ],
  [
    "6552",
    "NAD83(2011) / Oklahoma North"
  ],
  [
    "6554",
    "NAD83(2011) / Oklahoma South"
  ],
  [
    "6556",
    "NAD83(2011) / Oregon LCC"
  ],
  [
    "6558",
    "NAD83(2011) / Oregon North"
  ],
  [
    "6560",
    "NAD83(2011) / Oregon South"
  ],
  [
    "6562",
    "NAD83(2011) / Pennsylvania North"
  ],
  [
    "6564",
    "NAD83(2011) / Pennsylvania South"
  ],
  [
    "6566",
    "NAD83(2011) / Puerto Rico and Virgin Is."
  ],
  [
    "6569",
    "NAD83(2011) / South Carolina"
  ],
  [
    "6571",
    "NAD83(2011) / South Dakota North"
  ],
  [
    "6573",
    "NAD83(2011) / South Dakota South"
  ],
  [
    "6575",
    "NAD83(2011) / Tennessee"
  ],
  [
    "6577",
    "NAD83(2011) / Texas Central"
  ],
  [
    "6580",
    "NAD83(2011) / Texas Centric Lambert Conformal"
  ],
  [
    "6581",
    "NAD83(2011) / Texas North"
  ],
  [
    "6583",
    "NAD83(2011) / Texas North Central"
  ],
  [
    "6585",
    "NAD83(2011) / Texas South"
  ],
  [
    "6587",
    "NAD83(2011) / Texas South Central"
  ],
  [
    "6591",
    "NAD83(2011) / Virginia Lambert"
  ],
  [
    "6592",
    "NAD83(2011) / Virginia North"
  ],
  [
    "6594",
    "NAD83(2011) / Virginia South"
  ],
  [
    "6596",
    "NAD83(2011) / Washington North"
  ],
  [
    "6598",
    "NAD83(2011) / Washington South"
  ],
  [
    "6600",
    "NAD83(2011) / West Virginia North"
  ],
  [
    "6602",
    "NAD83(2011) / West Virginia South"
  ],
  [
    "6606",
    "NAD83(2011) / Wisconsin North"
  ],
  [
    "6608",
    "NAD83(2011) / Wisconsin South"
  ],
  [
    "6619",
    "NAD83(2011) / Utah Central"
  ],
  [
    "6620",
    "NAD83(2011) / Utah North"
  ],
  [
    "6621",
    "NAD83(2011) / Utah South"
  ],
  [
    "6794",
    "NAD83(2011) / Oregon Bend-Redmond-Prineville zone"
  ],
  [
    "6798",
    "NAD83(2011) / Oregon Bend-Burns zone"
  ],
  [
    "6806",
    "NAD83(2011) / Oregon Columbia River East zone"
  ],
  [
    "6854",
    "NAD83(2011) / Oregon Portland zone"
  ],
  [
    "6879",
    "NAD83(2011) / Wisconsin Central"
  ],
  [
    "6924",
    "NAD83(2011) / Kansas LCC"
  ],
  [
    "7111",
    "NAD83(2011) / RMTCRS Milk River"
  ],
  [
    "7112",
    "NAD83(2011) / RMTCRS Fort Belknap"
  ],
  [
    "7113",
    "NAD83(2011) / RMTCRS Fort Peck Assiniboine"
  ],
  [
    "7114",
    "NAD83(2011) / RMTCRS Fort Peck Sioux"
  ],
  [
    "7116",
    "NAD83(2011) / RMTCRS Bobcat"
  ],
  [
    "7117",
    "NAD83(2011) / RMTCRS Billings"
  ],
  [
    "7531",
    "NAD83(2011) / WISCRS Bayfield"
  ],
  [
    "7534",
    "NAD83(2011) / WISCRS Burnett"
  ],
  [
    "7536",
    "NAD83(2011) / WISCRS Chippewa"
  ],
  [
    "7538",
    "NAD83(2011) / WISCRS Columbia"
  ],
  [
    "7539",
    "NAD83(2011) / WISCRS Crawford"
  ],
  [
    "7540",
    "NAD83(2011) / WISCRS Dane"
  ],
  [
    "7545",
    "NAD83(2011) / WISCRS Eau Claire"
  ],
  [
    "7549",
    "NAD83(2011) / WISCRS Green and Lafayette"
  ],
  [
    "7550",
    "NAD83(2011) / WISCRS Green Lake and Marquette"
  ],
  [
    "7557",
    "NAD83(2011) / WISCRS Langlade"
  ],
  [
    "7559",
    "NAD83(2011) / WISCRS Marathon"
  ],
  [
    "7562",
    "NAD83(2011) / WISCRS Monroe"
  ],
  [
    "7564",
    "NAD83(2011) / WISCRS Oneida"
  ],
  [
    "7565",
    "NAD83(2011) / WISCRS Pepin and Pierce"
  ],
  [
    "7567",
    "NAD83(2011) / WISCRS Portage"
  ],
  [
    "7569",
    "NAD83(2011) / WISCRS Richland"
  ],
  [
    "7573",
    "NAD83(2011) / WISCRS Sawyer"
  ],
  [
    "7576",
    "NAD83(2011) / WISCRS Taylor"
  ],
  [
    "7578",
    "NAD83(2011) / WISCRS Vernon"
  ],
  [
    "7579",
    "NAD83(2011) / WISCRS Vilas"
  ],
  [
    "7580",
    "NAD83(2011) / WISCRS Walworth"
  ],
  [
    "7581",
    "NAD83(2011) / WISCRS Washburn"
  ],
  [
    "7585",
    "NAD83(2011) / WISCRS Waushara"
  ],
  [
    "7586",
    "NAD83(2011) / WISCRS Wood"
  ],
  [
    "8315",
    "NAD83(2011) / Oregon Coast Range North zone"
  ],
  [
    "8321",
    "NAD83(2011) / Oregon Halfway zone"
  ],
  [
    "8323",
    "NAD83(2011) / Oregon Medford-Diamond Lake zone"
  ],
  [
    "8325",
    "NAD83(2011) / Oregon Mitchell zone"
  ],
  [
    "8327",
    "NAD83(2011) / Oregon North Central zone"
  ],
  [
    "8329",
    "NAD83(2011) / Oregon Ochoco Summit zone"
  ],
  [
    "8333",
    "NAD83(2011) / Oregon Pilot Rock-Ukiah zone"
  ],
  [
    "8335",
    "NAD83(2011) / Oregon Prairie City-Brogan zone"
  ],
  [
    "8339",
    "NAD83(2011) / Oregon Siskiyou Pass zone"
  ],
  [
    "8341",
    "NAD83(2011) / Oregon Ukiah-Fox zone"
  ],
  [
    "8345",
    "NAD83(2011) / Oregon Warner Highway zone"
  ]
]