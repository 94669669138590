import FileUploadField from '../../../node_modules/acceladapt-online-lib/components/file_upload_field.js'
import {useState, useEffect} from "preact/hooks";
import {html} from "htm/preact";
import {Modal} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import {shortenName, formatLayerFilename, layer_filename} from "../../utils";

function getDate(inspected_date) {
  const date = new Date(inspected_date);
  const outputDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  return outputDate.toLocaleString();
}

const LayersList = ({project_id}) => {

  const [layers, setLayers] = useState([])
  const [project, setProject] = useState(null);
  const [boundaryLayer, setBoundaryLayer] = useState(null);
  const [deleteLayer, setDeleteLayer] = useState(null);

  const handleSetIntent = (layer, intent) => {
    window.app.set_layer_intent(project_id, layer.layer_id, intent);
  }

  const handleDeleteLayer = () => {

    if(deleteLayer === null) return;

    const clone = [...layers];
    const _layer = clone.find(l => l.layer_id === deleteLayer.layer_id);

    if(typeof _layer === 'undefined') {
      return;
    }

    _layer.being_deleted = true;
    setLayers(clone);

    window.app.delete_layer(project_id, deleteLayer.layer_id);

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-layer-modal'));
    modal.hide();
  }

  /**
   * Returns a string that represents a layer. This could be the original_file_layer, inspected_file, or layer_id field depending
   * on what property is valid in the given order.
   * @param layer
   * @param len
   * @returns {string}
   */
  const getValidLayerName = (layer, len) => {

    if(layer === null) {
      return 'Layer Null';
    }

    if(layer.original_file_layer !== null && typeof layer.original_file_layer !== 'undefined') {
      return shortenName(layer.original_file_layer, len);
    }

    if(layer.inspected_file !== null && typeof layer.inspected_file !== 'undefined') {
      return shortenName(formatLayerFilename(layer.inspected_file), len);
    }

    return shortenName(layer.layer_id, len);
  }

  useEffect(() => {

    const layers_stream = window.app.layers_stream.subscribe((data) => {

      if(data === null) return;

      const layer_ids = [];

      data.forEach(d => {
        d.status = 'unknown';
        layer_ids.push(d.layer_id);
      });

      if(data.length > 0) {
        window.app.fetch_layers_status(data[0].project_id, layer_ids).then(async (result) => {
          const json = await result.json();

          for(const key of Object.keys(json)) {
            const _temp = data.find(d => d.layer_id === key);

            if(typeof _temp === 'undefined') continue;

            _temp.status = json[key].status
            _temp.being_deleted = false;
          }

          setLayers(data);
        });

      } else {
        setLayers([]);
      }

    })

    const project_stream = window.app.project_stream.subscribe((data) => {

      if(data !== null && project !== null && data.project_id === project.project_id) {
        return;
      }

      setProject(data);
      window.app.fetch_layers(data.project_id);
    })

    return () => {
      layers_stream.unsubscribe();
      project_stream.unsubscribe();
    }
  }, [])

  useEffect(() => {

    if(project !== null && layers !== null) {
      if(project.hasOwnProperty('boundary_source_layer')) {
        setBoundaryLayer(layers.find(layer => layer.layer_id === project.boundary_source_layer));
      } else {
        setBoundaryLayer(null);
      }
    }

  }, [layers, project]);

  useEffect(async () => {

    if(boundaryLayer === null || typeof boundaryLayer === 'undefined') return;

    if(project === null) return;

    if(project.hasOwnProperty('boundary_source_layer')) {
      const currentLayer = project.boundary_source_layer;

      // don't want to update the boundary layer if it's the same as the current layer.
      if(boundaryLayer.layer_id === currentLayer) return;

      await window.app.create_project({project_id: project.project_id, title: project.title, place_common_name: project.place_common_name, place_proper_name: project.place_proper_name, project_epsg: project.project_epsg, boundary_source_layer: boundaryLayer.layer_id});

    } else {
      await window.app.create_project({project_id: project.project_id, title: project.title, place_common_name: project.place_common_name, place_proper_name: project.place_proper_name, project_epsg: project.project_epsg, boundary_source_layer: boundaryLayer.layer_id});

    }

  }, [boundaryLayer]);

  useEffect(() => {

    if(!!project_id) {
      window.app.user_projects_stream.subscribe((data) => {

        if(data == null) return;

        const _project = data.find(p => p.project_id === project_id);

        window.app.project_stream.next(typeof _project === 'undefined' ? null : _project);

      });
    }

  }, [project_id])

  useEffect(() => {

    if(deleteLayer === null) return;

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-layer-modal'));
    modal.show();

  }, [deleteLayer]);

  return html`
    <div class="bg-gray aap-shadow p-3">
      <div class="d-flex justify-content-center mb-3">
        <${FileUploadField} multiple=${true} />
      </div>
      <h6 style="text-align: center; color: #50BE97;">Upload all of your layers here, including the project boundary layer!</h6>

      <hr />
      
      <div>
        
        <h6 class="mb-3 fw-bold">Boundary Layer</h6>
        
        <div class="d-flex align-items-center">
          <div class="me-5">
            ${project !== null ? html`Project: <span>${project.title}</span> ` : html`Loading project <i class="ms-2 rotate-icon fa-light fa-spinner-third"></i>`}
          </div>

          <div>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                ${boundaryLayer === null || typeof boundaryLayer === 'undefined' ? 'Select Boundary Layer' : layers === null ? 'Layers' : `${boundaryLayer.hasOwnProperty('inspected_file') && typeof boundaryLayer.inspected_file === 'undefined' ? boundaryLayer.layer_id : boundaryLayer.inspected_file.split('/').slice(-1)[0]}`}
              </button>
              <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                ${
                    layers === null ? ''
                        :
                        layers.map((layer) => html`
                                <li class="dropdown-item d-flex justify-content-between" onclick=${() => {setBoundaryLayer(layer)}}>
                                  <span class="me-2">
                                    ${getValidLayerName(layer, 24)}
                                  </span>
                                  
                                  <span data-bs-toggle="tooltip" data-bs-html="true"
                                        data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file}>
                                    <i class="fa-light fa-circle-question "/>
                                  </span>
                                </li>
                            `)
                }
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr />
      
      <div class="overflow-auto">
        
        ${
          layers && layers.length > 0 ?
              html`
              <table className="table" style="text-align: center;">
                <thead>
                <tr>
                  <th scope="col">Original File</th>
                  <th scope="col">Layer Type</th>
                  <th scope="col">Inspected Date</th>
                  <th scope="col">Layer Intent <i class="fa-light fa-circle-question ms-1"/></th>
                  <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                ${
                    layers.map((layer) => html`
                      
                      <tr class="${layer.being_deleted ? 'layer-delete' : ''}">
                        
                        <td style="vertical-align: middle;" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" 
                            title=${getValidLayerName(layer, 500)}>
                          ${
                              layer.status === 'pending' || layer.status === 'running'
                                  ? html`<i className="me-2 rotate-icon fa-light fa-spinner-third"></i>` :
                                  layer.status === 'complete' 
                                      ? '' :
                                      layer.status === 'failed' ? html`<i class="me-2 fail-icon fa-solid fa-circle-exclamation" data-bs-toggle="tooltip" data-bs-html="true"
                                                                          data-bs-placement="right" title="Layer inspection failed!"></i>`
                                          : ''
                          }

                          ${
                      
                            (layer.status === 'pending' || layer.status === 'complete' || layer.status === 'failed') && layer.file_layer !== null ?
                                '' : 
                              html`<i class="me-2 warning-icon fa-solid fa-circle-exclamation" data-bs-toggle="tooltip" data-bs-html="true"
                                                                          data-bs-placement="right" title=${`The layer status is set to ${layer.status}${layer.file_layer === null ? ', but the file_layer property is null' : typeof layer.file_layer === 'undefined' ? ', but the file_layer property is undefined' : ''}`}></i>`
                          }
                          
                          ${getValidLayerName(layer, 24)}
                          ${layer.being_deleted ? html`<i class="rotate-icon delete-rotate-icon fa-light fa-spinner-third fa-2x"></i>` : ''}
                        </td>
                        <td style="vertical-align: middle;">${layer.hasOwnProperty('layer_type') ? layer['layer_type'] : '--'}</td>
                        <td style="vertical-align: middle;">${getDate(layer['inspected']) === 'Invalid Date' ? '--': getDate(layer['inspected'])}</td>
                        <td style="vertical-align: middle;">
                          <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              ${layer.hasOwnProperty('layer_intent') ? layer.layer_intent : 'Select Intent'}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'parcels') }}>Parcels</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'buildings') }}>Buildings</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'parcels_attributes') }}>Parcels Attributes</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'buildings_attributes') }}>Buildings Attributes</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'flood') }}>Flood</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'regulatory_flood') }}>Regulatory Flood</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'tree_canopy') }}>Tree Canopy</li>
                              <li class="dropdown-item" onclick=${() => { handleSetIntent(layer, 'land_cover') }}>Land Cover</li>
                            </ul>
                          </div>
                        </td>
                        <td  style="vertical-align: middle;">
                          <div class="delete-asset-threat" onclick=${() => setDeleteLayer(Object.assign({}, layer))}>
                            <i class="fa-light fa-trash-can"></i>
                          </div>
                        </td>
                      </tr>
                    `)
                }
                </tbody>
              </table>`
              
              :
              
              'No layers found.'
    
        }

      </div>
      
      <div style="text-align: right;">
        <button class="btn btn-primary mt-3">Start Analysis</button>
      </div>

      <div id="delete-layer-modal" class="modal" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Delete Layer</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete layer ${deleteLayer !== null ? html`<span style="color: #e74949; font-weight: bold;">${deleteLayer['title'] || layer_filename(deleteLayer['inspected_file'])}</span>` : 'layer'}?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-outline-error" onclick=${() => {handleDeleteLayer(deleteLayer !== null ? deleteLayer.layer_id : null)}} >Delete</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  `;
}

export default LayersList;
