import {useEffect, useState} from "preact/compat";
import {FlowNavSideContext} from "../flow_context.js";
import FlowNavSide from "../common_components/flow_nav_side.js";
import {html} from "htm/preact";
import LayerUploadField from "../common_components/layer_upload_field.js";
import {route} from "preact-router";


function ProjectBoundary({project_id}) {

  const [FlowNavSideState, setFlowNavSideState] = useState({
    values: ["Project", "Boundary"],
    current: 1
  });

  const [error, setError] = useState(null);
  const [boundary_source_layer, set_boundary_source_layer] = useState(null);


  useEffect(() => {
    window.app.project_stream.subscribe((project) => {
      set_boundary_source_layer(project?.boundary_source_layer)
    });
  }, [])


  const update_project_boundary_source_layer_id = async (expected_layer_id) => {
    this.base.querySelector('.triangle-spinner').classList.remove('d-none');

    const response = await (fetch(`${window.auth_manager.application_api_url}/projects`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        project_id,
        boundary_source_layer: expected_layer_id
      }),
      cors: true
    }).catch(window.auth_manager._access_denied_response))
    if (response.status === 200) {
      // const json_response = await response.json();
      // window.app.project_stream.next(json_response);
      handleNext();
    } else {
      this.base.querySelector('.triangle-spinner').classList.add('d-none');
      setError((await response.json())['error'] || 'Failed to create project!')
    }
  }

  const handleNext = () => {
    const next_url = new URL(window.location);
    next_url.pathname = "/modify.html"
    next_url.hash = `#/${project_id}/`
    window.location = next_url;
  }

  const handlePrevious = () => {
    const next_url = new URL(window.location);
    next_url.pathname = "/modify.html"
    next_url.hash = `#/${project_id}/`
    window.location = next_url;
  }

  return html`
    <${FlowNavSideContext.Provider} value=${FlowNavSideState}>

      <div>
        <div class="d-flex bg-gray aap-shadow p-3 mt-3 mb-3">

          <${FlowNavSide}/>

          <div class="col-12 col-lg-8 d-flex ms-5 flex-wrap mb-3">
            <label class="h6 form-label">Project Boundary Layer</label>
            <span data-bs-toggle="tooltip" class="ms-2" data-bs-placement="right" title="A vector polygon layer used to focus in on important data, ignoring things outside your control. You can change this whenever you like.">              
                    <i class="fa-light fa-circle-question "/>                 
                </span>
            <p>A polygon layer for the area to be assessed. Usually, this is either the jurisdictional boundary or the extent of government utilities and services. However, in sub-city scale projects, or projects where data
              is only available for a part of the jurisdiction, the boundary layer may be smaller than the jurisdiction.</p>
            <div class="px-1 flex-100">
              <${LayerUploadField} required=${true} layer_id=${boundary_source_layer} set_layer_id=${update_project_boundary_source_layer_id} expected_layer_type="vector_polygon" project_id="${project_id}"/>
            </div>
          </div>
          <div>
            ${
                error ? html`
                      <div class="mt-3 text-danger">
                        <span>${error}</span>
                      </div>
                    ` : ""
            }
          </div>
          <div class="d-flex justify-content-end mt-auto">
            <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous</button>
            <button class="btn btn-outline-primary-aap ps-4 pe-4" ${!boundary_source_layer ? 'disabled' : ''} onclick=${handleNext}>Next
              <div class="triangle-spinner float-end user-update-spinner d-none" style="margin: 0.15rem -0.25rem 0 0.5rem;"><span></span><span></span><span></span></div>
            </button>
          </div>
        </div>
      </div>
    <//>
  `

}

export default ProjectBoundary;