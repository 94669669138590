import {useContext} from "preact/compat";
import {FlowNavTopContext} from "../flow_context.js";
import {html} from "htm/preact";

function FlowNavTop() {

    const context = useContext(FlowNavTopContext);

    return html`
        <div class="flow-top bg-gray aap-shadow d-flex justify-content-between align-items-center mt-3">
            <div class="ps-3 pe-3 pt-1 pb-1 w-100 flow-item ${context === 0 ? "flow-top-active" : ""}">Module
            </div>
            <div  class="ps-3 pe-3 pt-1 pb-1 w-100 flow-item ${context === 1 ? "flow-top-active" : ""}">Datasets
            </div>
            <div  class="ps-3 pe-3 pt-1 pb-1 w-100 flow-item ${context === 2 ? "flow-top-active" : ""}">Asset Tagging
            </div>
            <div  class="ps-3 pe-3 pt-1 pb-1 w-100 flow-item ${context === 3 ? "flow-top-active" : ""}">Assessment Rules
            </div>
            <div  class="ps-3 pe-3 pt-1 pb-1 w-100 flow-item ${context === 4 ? "flow-top-active" : ""}">Review
            </div>
        </div>
    `
}

export default FlowNavTop;