import { useEffect, useState } from "preact/compat";
import { FlowNavTopContext, FlowNavSideContext } from "../flow_context.js";

import FlowNavSide from "../common_components/flow_nav_side.js";
import FlowNavTop from "../common_components/flow_nav_top.js";

import {html} from "htm/preact";
import {route} from "preact-router";

const DatasetsLayerInfo = (props) => {

    const [FlowNavTopState, setFlowNavTopState] = useState(1);
    const [FlowNavSideState, setFlowNavSideState] = useState({
        values: ["Dataset", "Layers"],
        current: 1
    });

    const handleNext = () => {
        route(`/${props.project_id}/m/${props.module_id}/asset-tags/`);
    }

    const handlePrevious = () => {
        route(`/${props.project_id}/m/${props.module_id}/datasets/`);
    }

    useEffect(() => {

    }, []);

    return html`
      <${FlowNavTopContext.Provider} value=${FlowNavTopState}>
        <${FlowNavSideContext.Provider} value=${FlowNavSideState}>

          <div>
            <${FlowNavTop}/>

            <div class="d-flex aap-shadow bg-gray p-3 mt-3 mb-3">

              <${FlowNavSide}/>

              <div class="d-flex flex-column justify-content-between ms-5 w-50" style="min-height: 30rem">
                <div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property Layer</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button" id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">For multi-file formats (such as GDB or GPKG), enter the layer name which should be used.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property ID field</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">The unique parcel identifier field / PIN.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property Use field</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">Most property datasets have a parcel use code or other description field which is used when classify permitted land uses. Leave blank if not available on this layer.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property Land Value</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">The parcel’s assessed land value. Leave blank if not available.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property Improvement Value</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">The building and or other improvement value on this property. Leave blank if not available.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Property Total Market Value</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">The parcel’s total assessed market value. If not available, use taxable value field or leave blank.</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between border-bottom pb-3 mb-2">
                    <div class="w-50">
                      <label htmlFor="file-layer" class="form-label">Effective Year Built for property</label>

                      <div class="dropdown">
                        <button class="btn btn-outline-dark dropdown-toggle" type="button"
                                id="file-layer" data-bs-toggle="dropdown" aria-expanded="false">
                          Select
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li><a class="dropdown-item" href="#">Another action</a></li>
                          <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    </div>

                    <div class="w-50">
                      <span style="color: #aea9a9">The effective year built of the primary structure on the parcel. Leave blank if not available.</span>
                    </div>
                  </div>

                </div>
                <div class="d-flex justify-content-end mt-auto">
                  <button class="btn btn-secondary me-2" onclick=${handlePrevious}>Previous</button>
                  <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${handleNext}>Next</button>
                </div>
              </div>

            </div>
          </div>


        </
        />
      <//>

    `;
}



export default DatasetsLayerInfo;
