import {html} from "htm/preact";
import FileUploadField from "../../../../acceladapt-online-lib/components/file_upload_field";
import {useEffect, useState} from "preact/compat";
import {Tooltip} from "../../../../acceladapt-online-lib/vendor/bootstrap.min.js";

function format_layer_filename(file) {
  // s3://acceladapt-pro-enclave-dev/protected/2816be83-1529-408f-9516-8983c76907e7/Buncombe_County_Boundary.zip
  if (!file) {
    return 'File not found'
  }
  file = file.split('/').slice(-1)[0];
  if (file.length <= 14) {
    return file
  }
  return file.slice(0, 7) + '…' + file.slice(-7)
}

function getDate(inspected_date) {
  const date = new Date(inspected_date);
  return date.toLocaleString();
}

function LayersSidePanel({selected_layer_id ,set_selected_layer, visible}) {

  const [layers, setLayers] = useState([])

  useEffect(() => {

    const tooltips = this.base.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((t) => {
      new Tooltip(t);
    })

    const subscription = window.app.layers_stream.subscribe(setLayers)
    window.app.project_stream.subscribe(({project_id})=>{
      window.app.fetch_layers(project_id)
    })

    return () => {
      subscription.unsubscribe()
    }

  }, []);

  useEffect(() => {
    console.log("Side Layers:", layers);
  }, [layers]);

  return html`
    <div class="${visible ? '' : 'd-none'}">
      <div class="d-flex justify-content-center">
        <${FileUploadField}/>
      </div>

      <div class="mt-5">

        <div>
          <span style="font-weight: bold;" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="Title">Existing Layers</span>
        </div>

        <div>

          ${
              layers && layers.length > 0 ?
                  html`
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Original File</th>
                  <th scope="col">Layer Type</th>
                  <th scope="col">Inspected Date</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                ${
                      layers.map((layer) => html`
                      <tr class="${(selected_layer_id && selected_layer_id.length > 0 && layer.layer_id === selected_layer_id) ? 'table-primary' : ''}">
                        <td style="vertical-align: middle;" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="${layer['original_file']}">${layer['title'] || format_layer_filename(layer['original_file'])}</td>
                        <td style="vertical-align: middle;">${layer['layer_type']}</td>
                        <td style="vertical-align: middle;">${getDate(layer['inspected'])}</td>
                        <td style="vertical-align: middle;"><button class="btn btn-outline-primary-aap" onclick=${() => {set_selected_layer(layer)}}>Select</button></td>
                      </tr>
                    `)
                  }
                </tbody>
              </table>`

                  :
                  'No layers found.'

          }
        </div>

      </div>
    </div>
  `
}

export default LayersSidePanel;