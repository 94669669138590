import {render} from 'preact';
import {html} from "htm/preact";
import Router, {Route} from 'preact-router';
import CreateProject from "./project_flow/create_project.js";
import ProjectsList from "./projects_list";
import ProjectBoundary from "./project_flow/project_boundary.js";
import {createHashHistory} from "history";

const IndexRoot = () => {
  return html`
      <${Router} history=${createHashHistory()} id="index-container">
        <${CreateProject} path="/create-project/"/>
        <${ProjectBoundary} path="/:project_id/boundary/"/>
        <${ProjectsList} path="/"/>
      <//>
  `
}

export default IndexRoot;

// This one inits itself ASAP
//render(html`<${IndexRoot}/>`, document.querySelector('body > div.container'))