import ProjectCard from "./common_components/project_card.js";
import {useEffect, useState, createRef} from "preact/compat";
import {html, render} from 'htm/preact';
import {route} from "preact-router";

function ProjectsList() {

  const [projects, setProjects] = useState([]);
  const projectsListRef = createRef();

  useEffect(() => {
    const subscription = window.app.user_projects_stream.subscribe((data) => {
      setProjects(data);
    });
    return () => subscription.unsubscribe();
  }, [])

  const createProject = () => {
    route(`/create-project/`);
  }

  return html`
    <div ref=${projectsListRef}>
      <div class="border cards-wrapper aap-shadow p-3 mt-3 mb-3">

        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h3 class="aap-title"><i class="fa fa-map-marker me-2"></i>My Projects</h3>
          </div>

          <div>
            <button class="btn btn-outline-dark" onclick=${createProject}>Create Project</button>
          </div>
        </div>

        <hr/>

        <div class="row g-2">

          ${
                projects === null || projects.length === 0 ? html`

                    <div class="d-flex align-items-center justify-content-center" style="min-height: 15rem;">
                      <h3 style="padding: 2rem 5rem; color: #dcdada;">No projects found.</h3>
                    </div>

                  `
                  :
                  projects.map((p, index) => html`
                    <div key=${index} class="col-xs-1 col-md-6 col-lg-4">
                      <${ProjectCard}
                          project=${p}
                      />
                    </div>
                  `)
          }

        </div>
      </div>

      
    </div>
  `;
}

export default ProjectsList;

render(html`<${ProjectsList}/>`, document.querySelector('body > div.container'))