import {Component, render} from 'preact';
import {html} from 'htm/preact';

import {$} from '../vendor/common.min.js';

export default class FileUploadField extends Component {
  /**
   *
   * @param multiple {?boolean}
   * @param expected_layer_type {?string}
   */
  constructor({multiple = null}) {
    super();
    this.state = {multiple: (multiple !== null ? multiple : false)};
    this.s3_credentials = null;


    if (!window.upload_manager) {
      import("../managers/upload_manager.js").then(({default: UploadManager}) => {
        window.upload_manager = new UploadManager()
      })
    }

  }

  async upload(file, animate_delay=0){
    window.upload_manager.upload_file(file);
    // add some pizzazz
    if (window.upload_manager._progress_dropdown.current) {
      window.upload_manager._progress_dropdown.current.animate_bubble(this.base, animate_delay)
    }
  }

  file_dropped(e) {
    $(this.base).find('.dropzone').removeClass('dragover');
    window.last_file_e = e;

    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          console.log('... file[' + i + '].name = ' + file.name);
          console.log(this.upload);
          this.upload(file, i*80 )
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        console.log('... file[' + i + '].name = ' + e.dataTransfer.files[i].name);
        this.upload(e.dataTransfer.files[i], i*80)
      }
    }
  }

  file_selected() {
    let files_objs = Array.from(this.base.querySelector('input[type="file"]').files)
    if (!files_objs.length) {
      return
    }
    if (!this.state.multiple) {
      files_objs = [files_objs[0]]
    }
    files_objs.forEach((file, i) => {

      this.upload(file, i*80);

      }
    );
  }

  render() {
    if (this._error) {
      return html`
        <div class="error" style="color: red;">${this._error}</div>`
    }
    // language=html
    return html`
      <div class="file-upload">
        <div class="dropzone border" ondrop="${this.file_dropped.bind(this)}" ondragover="${(e) => {
          e.preventDefault();
        }}" ondragenter="${(e) => {
          $(this.base).find('.dropzone').addClass('dragover');
          e.preventDefault();
        }}" ondragleave="${(e) => {
          $(this.base).find('.dropzone').removeClass('dragover');
          e.preventDefault();
        }}">
          <div class="d-flex flex-column justify-content-center align-items-center m-3">
            <div class="p-3 upload-icon" style="">
              <i class="fa-arrow-up-from-bracket fa-light fa-4x"></i>
            </div>
            <div class="mt-3" style="text-align: center;">
              <div class="mb-2">
                <span>Drag and drop ${this.state.multiple ? 'files' : 'a file'} here</span>
              </div>
              <span>
                    <label for="file-upload-input" class="btn btn-primary">Choose a file</label>
                </span>
            </div>
          </div>
        </div>
        <input type="file" id="file-upload-input" ${this.state.multiple ? 'multiple="multiple"' : ''} multiple="multiple" onchange="${this.file_selected.bind(this)}"/>
      </div>
    `;
  }


}



