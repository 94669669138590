import {html} from "htm/preact";
import {useEffect, useState} from "preact/compat";

const ModulesList = ({project_id}) => {

  const viewModuleTypes = async () => {
    window.app.module_flow_state_stream.next(1);
  }

  const [modules, setModules] = useState(null)

  const startAnalysis = async () => {

    await (fetch(`${window.auth_manager.application_api_url}/${props.project_id}/analyze`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.has_cognito_support ? window.auth_manager.cognito_tokens.access_token : ""
      }),
      cors: true
    }).then(async (response) => {

      if (response.status === 200) {
        console.log("status 200 started analysis", response.body);
      }
    }).catch((err) => {
      window.auth_manager._access_denied_response(err);
    }));
  }

  useEffect(() => {

    window.app.fetch_modules(project_id);

    const modules_stream = window.app.modules_stream.subscribe((data) => {
      setModules(data);
      console.log(data);
    });

    return () => {
      modules_stream.unsubscribe();
    }
  }, [])

  return html`
      <div class="bg-gray aap-shadow p-3">
        <div class="d-flex justify-content-between mb-3">
          <h3>Modules</h3>
          <button class="btn btn-outline-primary-aap d-flex align-items-center" onclick=${viewModuleTypes}><i class="fa fa-leaf me-2"></i>New Module</button>
        </div>
        <hr/>

        <div class="d-flex-md-up">
          ${
              modules === null ?
                  html`
                    <div class="border p-2 me-md-up-2 mb-md-down-2" style="min-width: 12rem; min-height: 5rem;">
                      <div class="mb-3 border-bottom">
                        <div class="loading loading-text mb-3 w-50"></div>
                      </div>
                      <div>
                        <div class="loading loading-text mb-1 w-25"></div>
                        <div class="loading loading-text mb-1 w-70"></div>
                        <div class="loading loading-text w-50"></div>
                      </div>
                    </div>

                    <div class="border p-2 me-md-up-2 mb-md-down-2" style="min-width: 12rem; min-height: 5rem;">
                      <div class="mb-3 border-bottom">
                        <div class="loading loading-text mb-3 w-50"></div>
                      </div>
                      <div>
                        <div class="loading loading-text mb-1 w-25"></div>
                        <div class="loading loading-text mb-1 w-70"></div>
                        <div class="loading loading-text w-50"></div>
                      </div>
                    </div>

                    <div class="border p-2 me-md-up-2 mb-md-down-2" style="min-width: 12rem; min-height: 5rem;">
                      <div class="mb-3 border-bottom">
                        <div class="loading loading-text mb-3 w-50"></div>
                      </div>
                      <div>
                        <div class="loading loading-text mb-1 w-25"></div>
                        <div class="loading loading-text mb-1 w-70"></div>
                        <div class="loading loading-text w-50"></div>
                      </div>
                    </div>

                  `
                  :

                  modules && Object.keys(modules).length > 0 ?
                      Object.keys(modules).map((module, index) => html`
                        <div key=${index} class="border p-2 me-md-up-2 mb-md-down-2">
                          <div class="border-bottom mb-2 pb-1" style="font-weight: bold">
                            ${modules[module].label}
                          </div>
                          <div class="d-flex justify-content-between">
                            <span>ID: ${modules[module].module_id}</span>
                          </div>
                        </div>
                      `)
                      :
                      html`<div class="text-center">No modules found</div>`
            }
        </div>

        <div style="text-align: right;">
          <button class="btn btn-primary mt-3" onclick=${startAnalysis}>Start Analysis</button>
        </div>
      </div>
  `;
}

export default ModulesList;
