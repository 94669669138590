import {html} from "htm/preact";
import {createRef, useEffect, useState} from "preact/compat";
import {Tooltip} from "../../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";

function ThreatCondition({show, title, tooltip, buttonName, handleData}) {

  const globalRef = createRef();

  const [label, setLabel] = useState("");
  const [id, setId] = useState("");
  const [hml, setHML] = useState("");
  const [query, setQuery] = useState("");
  const [items, setItems] = useState([]);

  const setToolTip = (title, message) => {
    return `
                <div>
                    <h6>${title}</h6>
                    <p>
                    ${message}
                    </p>
                </div>
            `
  }

  const handleAddItems = () => {

    if (label.length === 0 || id.length === 0 || hml.length === 0 || query.length === 0) {
      console.log("length is 0");
      return;
    }

    if (hml.toUpperCase() !== 'H' && hml.toUpperCase() !== 'M' && hml.toUpperCase() !== 'L') {
      console.log("not HML");
      return;
    }

    if (items.find(c => c.id === id) > -1) {
      // already exists with that id
      console.log("found > -1");
      return;
    }

    setItems([...items, {label: label, id: id, hml_value: hml, query: query}]);
    setLabel("");
    setId("");
    setHML("");
    setQuery("");
  }

  useEffect(() => {
    globalRef.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new Tooltip(element));
  }, [])

  useEffect(() => {
    handleData(items);
  }, [items])


  return html`
    <div ref=${globalRef} class="${show ? 'mb-2' : 'd-none'}">
      <div class="d-flex justify-content-between mb-2 fw-bold">
        <label class="form-label">${title}</label>
        <span data-bs-toggle="tooltip" data-bs-html="true"
              data-bs-placement="right" title=${setToolTip(tooltip.title, tooltip.desc)}><i
            class="fa-light fa-circle-question "/></span>
      </div>

      <div class="d-flex-md-up">
        <div class="mb-2 me-2">
          <div>
            <label htmlFor="condition-label" class="form-label">Label</label>
          </div>
          <input type="text" class="form-control aap-input"
                 placeholder="Label" onchange=${(e) => {
    setLabel(e.target.value)
  }} value=${label}/>
        </div>
        <div class="mb-2">
          <div>
            <label htmlFor="condition-id" class="form-label">ID</label>
          </div>
          <input type="text" class="form-control aap-input"
                 placeholder="ID" onchange=${(e) => {
    setId(e.target.value)
  }} value=${id}/>
        </div>
      </div>

      <div class="d-flex-md-up">
        <div class="mb-2 me-2">
          <div>
            <label htmlFor="condition-query" class="form-label">H, M, L</label>
          </div>
          <input type="text" class="form-control aap-input"
                 placeholder="'H', 'M', or 'L'" onchange=${(e) => {
    setHML(e.target.value)
  }} value=${hml}/>
        </div>

        <div class="mb-2">
          <div>
            <label htmlFor="condition-query" class="form-label">Query</label>
          </div>
          <input type="text" class="form-control aap-input"
                 placeholder="Query" onchange=${(e) => {
    setQuery(e.target.value)
  }} value=${query}/>
        </div>
      </div>

      ${typeof items !== 'undefined' && items.length > 0 ? items.map(p => (
      html`
            <div>${p.label} (${p.id})</div>
          `
  )) : ''}

      <button class="btn btn-primary mb-2" onclick=${handleAddItems}>${buttonName}</button>
    </div>

  `
}

export default ThreatCondition;