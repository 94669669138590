import FileUploadField from '../../../node_modules/acceladapt-online-lib/components/file_upload_field.js';


/**
 * A variant of FileUploadField which has an expected layer type and will create/return a layer_id as soon as the user uploads a file. Does not support multiple files.
 */
export default class LayerUploadField extends FileUploadField {
  constructor({project_id,  expected_layer_type, layer_id, set_layer_id}) {
    super({multiple:true})
    this.project_id = project_id
    this.expected_layer_type = expected_layer_type
    this.set_layer_id = set_layer_id
  }

  async upload(file, animate_delay){

    console.log("Layer upload field");

    const expected_layer = await (await (fetch(`${window.auth_manager.application_api_url}/${this.project_id}/layers`, {
      method: 'PUT',
      body: JSON.stringify({
        access_token: window.auth_manager.cognito_tokens.access_token,
        layer_type: this.expected_layer_type
      }),
      cors: true
    }).catch(window.auth_manager._access_denied_response))).json();
    if (this.set_layer_id){ // callback
      this.set_layer_id(expected_layer['layer_id'])
    }

    window.upload_manager.upload_file(file,this.project_id, expected_layer['layer_id'],this.expected_layer_type );
    // add some pizzazz
    if (window.upload_manager._progress_dropdown.current) {
      window.upload_manager._progress_dropdown.current.animate_bubble(this.base, animate_delay)
    }
  }


}
