import {useEffect, useState} from "preact/compat";

import {Tooltip, Modal} from "../../../node_modules/acceladapt-online-lib/vendor/bootstrap.min.js";
import {html} from "htm/preact";
import {shortenName, layer_filename} from "../../utils";

const AssetTags = ({project_id, module_id}) => {

  const [name, setName] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  const [parcelsLayer, setParcelsLayer] = useState(null);

  const [layers, setLayers] = useState(null);

  const [deleteTag, setDeleteTag] = useState("");

  const [sections, setSections] = useState([]);
  const [components, setComponents] = useState([]);

  const list_column_values = (layer) => {

    if (typeof layer === 'undefined' || layer === null) return [];

    const output = layer.columns.map((col, index) => ({col, data_type: layer.column_dtypes[index]}));

    return output;
  }

  const handleSplitText = (text) => {

    if(text.length === 0) return [];

    let _text = text.split(',');

    _text = _text.map(v => v.trim()).filter(v => v.length > 0);

    return _text;
  }

  const doesTagExist = (tag) => {

    if(typeof selectedLayers === 'undefined' || selectedLayers.length === 0) return false;

    for(const l of selectedLayers) {
      if(!l.hasOwnProperty('tag_queries')) continue;

      if(Object.keys(l.tag_queries).includes(tag)) return true;
    }

    return false;
  }

  const handleSave = async () => {

    if(disableBtn) {
      console.log("Save btn is disabled to prevent double clicks.");
      return;
    }

    if (typeof parcelsLayer === 'undefined' || parcelsLayer === null) {
      console.log("Parcels layer is null");
      return;
    }

    if (name.length === 0) {
      console.log("Must set a name");
      return;
    }

    /*
    We start each tag query from the beginning, because this way we don't have to create a separate function for editing and saving.
    */
    let tag_queries = {};
    tag_queries[name] = [];

    for(let i = 0; i < sections.length; i++) {

      const s = sections[i];
      let obj = [];

      for(const c of components) {

        if(c.section !== s) continue;

        tag_queries[name][i] = {};

        let localObj = {};

        if(c.type === 'tags') {
          localObj = {
            tags: handleSplitText(c.state.tags)
          }
        } else if(c.type === 'column-values') {
          localObj = {
            column: c.state.column.col,
            values: handleSplitText(c.state.values)
          }
        } else if(c.type === 'join') {

          if(c.state.layer === null) {
            console.log("Join Layer is null");
            continue;
          }

          if(c.state.right_on.length > 0) {

            if(c.state.right_on.toLowerCase() === 'nearest_point') {
              localObj.right_on = 'nearest_point';
              localObj.nearest_tolerance = c.state.right_nearest_tolerance;
            } else {
              localObj.right_on = c.state.right_on;
            }

          }

          if(c.state.left_on.length > 0) {

            if(c.state.left_on.toLowerCase() === 'nearest_point') {
              localObj.left_on = 'nearest_point';
              localObj.nearest_tolerance = c.state.left_nearest_tolerance;
            } else {
              localObj.left_on = c.state.left_on;
            }
          }

          // must have at least one of the fields filled out.
          if(localObj.left_on.length === 0 && localObj.right_on.length === 0) {
            console.log("join left_on and right_on are empty, one of them must be filled out.");
            continue;
          }

          if(c.state.column !== null && c.state.values.length > 0) {
            localObj.column = c.state.column.col;
            localObj.values = handleSplitText(c.state.values);
          }

          localObj.layer = c.state.layer.layer_id;

        }

        obj.push(localObj);
      }

      tag_queries[name] = obj;
    }

    console.log("tag_queries:", tag_queries);

    let existingTags = parcelsLayer.hasOwnProperty('tag_queries') ? parcelsLayer.tag_queries : {};

    /* merge existing tags with new tags*/
    existingTags = {
      ...existingTags,
      ...tag_queries
    }

    if(!parcelsLayer.layer_id) {
      console.log("layer id is not defined")
      return;
    }

    const body = {
      access_token: window.auth_manager.has_cognito_support ? window.auth_manager.cognito_tokens.access_token : "",
      layer_id: parcelsLayer.layer_id,
      layer_type: parcelsLayer.layer_type,
      tag_queries: existingTags
    }

    resetInputs();

    await (fetch(`${window.auth_manager.application_api_url}/${project_id}/layers`, {
      method: 'PUT',
      body: JSON.stringify(body),
      cors: true
    }).then(async (response) => {

      if (response.status === 200) {
        console.log("status 200, fetching layers again");
        resetInputs();
        setDisableBtn(false);

        window.app.fetch_layers(project_id);
      }
    }).catch((err) => {
      setDisableBtn(false);
      window.auth_manager._access_denied_response(err);
    }));

  }

  /**
   * Populate the asset tag fields after clicking on the Edit button.
   * @param tagName
   * @param tag
   */
  const handlePopulateFields = (tagName, tag) => {

    if(tag.length === 0) return;

    setName(tagName);

    const _sections = [];
    const _components = [];

    const _columns = list_column_values(parcelsLayer);

    for(const s of tag) {

      const sectionId = "id" + Math.random().toString(16).slice(2);
      _sections.push(sectionId);

      let checkedJoin = false;
      let checkedColumn = false;

      const componentKeys = Object.keys(s);

      for(const c of componentKeys) {
        if(c === 'tags') {
          _components.push({section: sectionId, id: "comp-" + Math.random().toString(16).slice(2), type: 'tags', state: {tags: s.tags.join(', ')}});
        } else if((componentKeys.includes('left_on') || componentKeys.includes('right_on')) && !checkedJoin) {

          checkedJoin = true;
          checkedColumn = true;

          const hasRight = componentKeys.includes('right_on');
          const hasLeft = componentKeys.includes('left_on');

          const left_on = hasLeft ? s['left_on'] : '';
          const left_nearest_tolerance = hasLeft ? s['left_on'] === 'nearest_point' ? s['nearest_tolerance'] : '' : '';

          const right_on = hasRight ? s['right_on'] : '';
          const right_nearest_tolerance = hasRight ? s['right_on'] === 'nearest_point' ? s['nearest_tolerance'] : '' : '';

          const column = typeof s['column'] === 'undefined'
              ? null
              : typeof _columns.find(col => col.col === s['column']) === 'undefined'
                  ? null
                  : _columns.find(col => col.col === s['column']);

          const values = column === null ? '' : s['values'].join(', ');

          const _layer = layers.find(l => l.layer_id === s['layer']);
          const layer = typeof _layer === 'undefined' ? null : _layer;

          _components.push({section: sectionId, id: "comp-" + Math.random().toString(16).slice(2), type: 'join',
            state: {
              column,
              values,
              left_on,
              right_on,
              left_nearest_tolerance,
              right_nearest_tolerance,
              layer
            }});
        }  else if(c === 'column' && !checkedColumn) {
          _components.push({section: sectionId, id: "comp-" + Math.random().toString(16).slice(2), type: 'column-values', state: {column: _columns.find(col => col.col === s.column), values: s.values.join(', ')}});
        }
      }
    }

    setSections(_sections);
    setComponents(_components);

  }

  const resetInputs = () => {
    setName("");
    setSections([]);
    setComponents([]);
  }

  const handleDeleteTag = async () => {

    if(deleteTag.length === 0) return;

    if(parcelsLayer === null) return;

    if(!parcelsLayer.hasOwnProperty('tag_queries')) return;

    if(!Object.keys(parcelsLayer.tag_queries).includes(deleteTag)) return;

    delete parcelsLayer.tag_queries[deleteTag];

    const body = {
      access_token: window.auth_manager.has_cognito_support ? window.auth_manager.cognito_tokens.access_token : "",
      layer_id: parcelsLayer.layer_id,
      layer_type: parcelsLayer.layer_type,
      tag_queries: parcelsLayer.tag_queries
    }

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-tag-modal'));
    modal.hide();

    await (fetch(`${window.auth_manager.application_api_url}/${project_id}/layers`, {
      method: 'PUT',
      body: JSON.stringify(body),
      cors: true
    }).then(async (response) => {

      if (response.status === 200) {
        console.log("status 200, fetching layers again");
      }
    }).catch((err) => {
      setDisableBtn(false);
      window.auth_manager._access_denied_response(err);
    }));

  }

  const createSection = () => {
    if(sections.length > 0) return;
    setSections([...sections, "id" + Math.random().toString(16).slice(2)]);
  }

  /**
   * Takes the component, key of the component, and the value you want it updated to.
   * @param component
   * @param key
   * @param value
   */
  const handleComponentStateChange = (component, key, value) => {
    const clone = [...components];

    const _comp = clone.find(c => c.id === component.id);

    if(typeof _comp === 'undefined') return;

    _comp.state[key] = value;

    setComponents(clone);
  }

  const removeComponent = (c) => {
    const _clone = [...components];
    setComponents(_clone.filter(comp => comp.id !== c.id));
  }

  const removeSection = (section) => {
    const _sectionClone = [...sections];
    setSections(_sectionClone.filter(s => s !== section));
    setComponents(components.filter(comp => comp.section !== section));
  }

  const renderSectionComponent = (c) => {

    /*
     {
        right_on: '',
        right_value: '',
        right_nearest_tolerance: -1,
        left_on: '',
        left_value: '',
        left_nearest_tolerance: -1,
        layer: null,
        column: null,
        values: ''
      }
    */

    switch(c.type) {
      case 'tags':
        return html`
        <div class="mb-2 component">

          <h5 class="fw-bold">Add Tags</h5>
          
          <label htmlFor="child-tags" class="form-label me-3">Tags</label>
          <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="Tags information"><i class="fa-light fa-circle-question "/></span>
          <textarea class="form-control" id="child-tags" rows="4" onchange=${(e) => {handleComponentStateChange(c, 'tags', e.target.value)}} value=${c.state.tags} />
          <div class="d-flex flex-wrap">
          ${
              handleSplitText(c.state.tags).map(tag => (
                  html`
                    <div style="background-color: #3bb482; color: white;" class="me-1 mt-2">
                      <span className="p-1 m-1">${tag}</span>
                    </div>
                  `
              ))
          }
          </div>

          <i class="fa-light fa-trash-can delete-component" onclick=${() => removeComponent(c)}></i>
          
          <hr />
        </div>
        `
      case 'column-values':
        return html`
          <div class="mb-2 component">
            <h5 class="fw-bold">Column and Values</h5>
            <div>
              <label class="form-label">Column</label>
              <div class="dropdown">
                <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  ${c.state.column === null ? 'Select a Column' : `${c.state.column.col} (${c.state.column.data_type})`}
                </button>
                <ul class="dropdown-menu" aria-labelledby="flood-dropdown">
                  ${
                      list_column_values(parcelsLayer).map((item) => (
                          html`
                            <li class="dropdown-item" onclick=${() => {
                              handleComponentStateChange(c, 'column', item)
                            }}>${item.col} (${item.data_type})
                            </li>`
                      ))
                  }
                </ul>
              </div>
            </div>
            
            <div class="mt-2">
              <label htmlFor="child-tags" class="form-label me-3">Values</label>
              <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="Tags information"><i class="fa-light fa-circle-question "/></span>
              <textarea class="form-control" id="child-tags" rows="4" onchange=${(e) => {handleComponentStateChange(c, 'values', e.target.value)}} value=${c.state.values} />

              <div class="d-flex flex-wrap">
                ${
                    handleSplitText(c.state.values).map(val => (
                        html`
                    <div style="background-color: #3bb482; color: white;" class="me-1 mt-2">
                      <span className="p-1 m-1">${val}</span>
                    </div>
                  `
                    ))
                }
              </div>
            
            </div>

            <i class="fa-light fa-trash-can delete-component" onclick=${() => removeComponent(c)}></i>
            <hr />
          </div>
        `
      case 'join':
        return html`
        <div class="mb-2 component">
          <h5 class="fw-bold">Join</h5>
          <div>

            <div class="mb-3">
              <label class="form-label">Join Layer</label>

              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  ${(typeof c.state.layer === 'undefined' || c.state.layer === null) ? 'Layers' : layers === null ? 'Layers' : `${typeof c.state.layer.inspected_file === 'undefined' ? shortenName(c.state.layer.layer_id, 25) : shortenName(c.state.layer.inspected_file.split('/').slice(-1)[0], 25)}`}
                </button>
                <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                  ${
                      layers === null ? ''
                          :
                          layers.map((layer, index) => html`
                                  <li class="dropdown-item d-flex-md-up d-block-md justify-content-between" onclick=${() => {handleComponentStateChange(c, 'layer', layer)}}>
                                    <span class="me-2">${layer !== null && typeof layer.inspected_file === 'undefined' ? shortenName(layer.layer_id, 25) : shortenName(layer.inspected_file.split('/').slice(-1)[0], 25)}</span>
                                    <span data-bs-toggle="tooltip" data-bs-html="true"
                                          data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file}>
                                      <i class="fa-light fa-circle-question "/>
                                    </span>
                                  </li>
                              `)
                  }
                </ul>
              </div>
            </div>

            <div class="mb-2">
              <div class="mb-1 w-100-md">
                <div class="d-flex align-items-center">Right On</div>

                <div class="d-flex w-100-md">
                  <input type="text" class="form-control aap-input w-100-md me-1" onchange=${(e) => {
                    handleComponentStateChange(c, 'right_on', e.target.value)
                  }} value=${c.state.right_on}/>
                  <div class="dropdown">
                    <button class="btn btn-outline-dark dropdown-toggle ${c.state.layer === null ? 'disabled' : ''}" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu" aria-labelledby="flood-dropdown">
                      ${
                          list_column_values(c.state.layer).map((item) => (
                              html`
                                <li class="dropdown-item" onclick=${() => {
                                  handleComponentStateChange(c, 'right_on', item.col)
                                }}>${item.col} (${item.data_type})
                                </li>`
                          ))
                      }
                    </ul>
                  </div>
                </div>

                <div class="d-flex align-items-center ${c.state.right_on.toLowerCase() === 'nearest_point' ? 'mt-2' : 'd-none'}">Nearest Tolerance</div>
                <div class="d-flex w-100-md ${c.state.right_on.toLowerCase() === 'nearest_point' ? 'mb-2' : 'd-none'}">
                  <input type="number" min="0" step="1" class="form-control aap-input w-100-md" onchange=${(e) => {
                    handleComponentStateChange(c, 'right_nearest_tolerance', e.target.value)
                  }} value=${c.state.right_on}/>
                </div>
                
              </div>

              

              <div class="w-100-md">
                <div class="d-flex align-items-center">Left On</div>

                <div class="d-flex w-100-md">
                  <input type="text" class="form-control aap-input w-100-md me-1" onchange=${(e) => {
                    handleComponentStateChange(c, 'left_on', e.target.value)
                  }} value=${c.state.left_on}/>
                  <div class="dropdown">
                    <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu" aria-labelledby="flood-dropdown">
                      ${
                          list_column_values(parcelsLayer).map((item) => (
                              html`
                                <li class="dropdown-item" onclick=${() => {
                                  handleComponentStateChange(c, 'left_on', item.col)
                                }}>${item.col} (${item.data_type})
                                </li>`
                          ))
                      }
                    </ul>
                  </div>
                </div>

                <div class="d-flex align-items-center ${c.state.left_on.toLowerCase() === 'nearest_point' ? 'mt-2' : 'd-none'}">Nearest Tolerance</div>
                <div class="d-flex w-100-md ${c.state.left_on.toLowerCase() === 'nearest_point' ? 'mb-2' : 'd-none'}">
                  <input type="number" min="0" step="1" class="form-control aap-input w-100-md" onchange=${(e) => {
                    handleComponentStateChange(c, 'left_nearest_tolerance', e.target.value)
                  }} value=${c.state.left_on}/>
                </div>
                
              </div>

            </div>
  
            <div>
              <label class="form-label">Column</label>
              <div class="dropdown">
                <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  ${c.state.column === null ? 'Select a Column' : `${c.state.column.col} (${c.state.column.data_type})`}
                </button>
                <ul class="dropdown-menu" aria-labelledby="flood-dropdown">
                  ${
                      list_column_values(parcelsLayer).map((item) => (
                          html`
                              <li class="dropdown-item" onclick=${() => {
                            handleComponentStateChange(c, 'column', item)
                          }}>${item.col} (${item.data_type})
                              </li>`
                      ))
                  }
                </ul>
              </div>
            </div>

            <div class="mt-2">
              <label htmlFor="child-tags" class="form-label me-3">Values</label>
              <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="Tags information"><i class="fa-light fa-circle-question "/></span>
              <textarea class="form-control" id="child-tags" rows="4" onchange=${(e) => {handleComponentStateChange(c, 'values', e.target.value)}} value=${c.state.values} />

              <div class="d-flex flex-wrap">
                ${
                    handleSplitText(c.state.values).map(val => (
                        html`
                    <div style="background-color: #3bb482; color: white;" class="me-1 mt-2">
                      <span className="p-1 m-1">${val}</span>
                    </div>
                  `
                    ))
                }
              </div>

            </div>
            
          </div>

          <i class="fa-light fa-trash-can delete-component" onclick=${() => removeComponent(c)}></i>
          
          <hr />
        </div>
        `
      default:
        return html`
        <div>
          
        </div>
        `
    }

  }

  useEffect(() => {

    window.app.fetch_layers(project_id)

    const layers_stream = window.app.layers_stream.subscribe((layers) => {
      if(layers !== null) {
        setLayers(layers.filter(l => l.layer_type === 'vector_polygon'));
      }
    });

    const project_stream = window.app.project_stream.subscribe((data) => {
      window.app.fetch_layers(data.project_id)
    })

    document.getElementById('delete-tag-modal').addEventListener('hide.bs.modal', function (event) {
      setDeleteTag('');
    })

    return () => {
      layers_stream.unsubscribe();
      project_stream.unsubscribe();
    }

  }, []);

  useEffect(() => {

    if(typeof parcelsLayer === 'undefined' || parcelsLayer === null) {
      return;
    }

    // if layers change, make sure the selected parcels layer is in the list.
    for(const l of layers) {

      if(l.layer_id === parcelsLayer.layer_id) {
        setParcelsLayer(l);
        console.log("Updating parcels layer.");
        return;
      }
    }

    // the selected parcels layer was not in the new layers list, setting parcels layer to null
    setParcelsLayer(null);

  }, [layers]);

  useEffect(() => {
  }, [parcelsLayer]);

  useEffect(() => {

    if(deleteTag.length === 0) return;

    const modal = Modal.getOrCreateInstance(document.getElementById('delete-tag-modal'));
    modal.show();

  }, [deleteTag]);

  return html`
      <div>
        <div class="aap-shadow bg-gray p-3 mb-3">

          <div class="d-flex-md-up">
          
            <div class="w-100-md w-50-md-up">
              
              <div>
                
                <div>
                  <label htmlFor="module-type" class="form-label">Select Parcel layers</label>
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="layers-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                      ${(typeof parcelsLayer === 'undefined' || parcelsLayer === null) ? 'Layers' : layers === null ? 'Layers' : `${typeof parcelsLayer.inspected_file === 'undefined' ? shortenName(parcelsLayer.layer_id, 30) : shortenName(parcelsLayer.inspected_file.split('/').slice(-1)[0], 30)}`}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="layers-dropdown">
                      ${
                          layers === null ? ''
                              :
                              layers.map((layer, index) => html`
                                  <li class="dropdown-item d-flex justify-content-between" onclick=${() => {setParcelsLayer(layer)}}>
                                    <span class="me-2">${layer !== null && typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file.split('/').slice(-1)[0]}</span>
                                    <span data-bs-toggle="tooltip" data-bs-html="true"
                                          data-bs-placement="right" title=${typeof layer.inspected_file === 'undefined' ? layer.layer_id : layer.inspected_file}>
                                      <i class="fa-light fa-circle-question "/>
                                    </span>
                                  </li>
                              `)
                      }
                    </ul>
                  </div>
                </div>

                <div class="d-flex justify-content-between my-3 ${parcelsLayer === null ? 'd-none' : ''}">
                  <div>
                    <label for="tag-name" class="form-label">Tag Name</label>
                    <div class="input-group">
                      <input type="text" class="form-control aap-input" id="tag-name" placeholder="Asset Tag Name" onchange=${(e) => {
                        setName(e.target.value)
                      }} value=${name}/>
                    </div>
                  </div>
                  <div class="mt-auto">
                    <button class="d-none-md btn btn-outline-primary-aap" onclick=${createSection}><i class="fa-light fa-circle-plus"></i> Section</button>
                    <button class="d-none-md-up btn btn-outline-primary-aap" onclick=${createSection}><i class="fa-light fa-circle-plus"></i></button>
                  </div>
                </div>
                
              </div>
  
              <div>
                
                ${sections.map((section) => (
                    
                    html`
                    <div class="tag-section my-2 p-2">
                   
                      ${components.filter(comp => comp.section === section).map((c) => (
                          html`
                            ${renderSectionComponent(c)}
                          `
                      ))}
                      
                      <div class="d-flex justify-content-between">
                      
                        <div class="dropdown">
                          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Add Component
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="flood-dropdown">
                            <li><span class="dropdown-item" onclick=${() => {setComponents([...components, {section, id: "comp-" + Math.random().toString(16).slice(2), type: 'tags', state: {tags: ''}}])} }>Add Tags</span></li>
                            <li><span class="dropdown-item" onclick=${() => {setComponents([...components, {section, id: "comp-" + Math.random().toString(16).slice(2), type: 'column-values', state: {column: null, values: ''}}])} }>Column and Values</span></li>
                            <li><span class="dropdown-item" onclick=${() => {setComponents([...components, {
                              section, 
                              id: "comp-" + Math.random().toString(16).slice(2), 
                              type: 'join', 
                              state: {
                                right_on: '',
                                right_value: '',
                                right_nearest_tolerance: -1,
                                left_on: '',
                                left_value: '',
                                left_nearest_tolerance: -1,
                                layer: null, 
                                column: null, 
                                values: ''
                              }
                            }])} 
                      }>Join</span></li>
                            
                            <li><span class="dropdown-item" onclick=${() => {setComponents([...components, {section, id: "comp-" + Math.random().toString(16).slice(2), type: 'custom-json', state: {json: ''}}])} }>Custom JSON</span></li>
                          </ul>
                        </div>
                          
                        <button class="btn btn-outline-danger d-none-md" onclick=${() => removeSection(section)}>Remove Section</button>
                        <button class="btn btn-outline-danger d-none-md-up" onclick=${() => removeSection(section)}><i class="fa-light fa-trash-can"></i></button>
                      </div>
                        
                    </div>
                    
                    `
                ))}

                ${components.length > 0 ?
                    html`
                      <div class="mt-2 mb-md-down-2" style="text-align: end;">
                        <button class="btn btn-outline-warning ps-4 pe-4 me-2" onclick=${resetInputs}>Cancel</button>
                        <button class="btn btn-outline-primary-aap ps-4 pe-4" onclick=${handleSave} disabled=${disableBtn}>Save</button>
                      </div>
                    `
                    :
                    html``
                }
                
              </div>
  
            </div>

            <div class="w-50-md-up left-border-md-up top-border-md mt-md-down-2 pt-md-down-2 ms-md-3 ps-md-3" style="text-align: center;">
              
              ${
                  (typeof parcelsLayer === 'undefined' || parcelsLayer === null) ? html`<div>Select a layer to view configured Tag Queries.</div>` :
                      
                      parcelsLayer !== null && parcelsLayer.hasOwnProperty('tag_queries') && Object.keys(parcelsLayer.tag_queries).length > 0 ?
  
                          html`
                        <div class="fw-bold mb-3">Configured Tag Queries</div>
                        ${
                            Object.keys(parcelsLayer.tag_queries).map(tag => (
                                  html`
                                  <div class="d-flex py-2 border-bottom">
                                    <div class="d-flex align-items-center justify-content-between me-auto">
                                      <i class="me-3 fa-light fa-circle-info" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="right" title="Set tooltip later"></i><span>${tag}</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end" >
                                      <i class="fa-solid fa-pen-to-square me-1" onclick=${() => handlePopulateFields(tag, parcelsLayer.tag_queries[tag])}></i>
                                      <i class="fa-light fa-trash-can delete-asset-threat" onclick=${() => {setDeleteTag(tag)}}></i>
                                    </div>
                                  </div>
                                  `
                              ))
  
                          }
                        `
                          :
                          html`<div>No Tag Queries have been configured on this layer.</div>`
              }
            </div>

          </div>
          
        </div>

        <div id="delete-tag-modal" class="modal" tabindex="-1">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">${deleteTag.length > 0 ? deleteTag : 'Title'}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete the Tag Query with ID ${deleteTag.length > 0 ? html`<span style="color: #e74949; font-weight: bold;">${deleteTag}</span>` : 'id'}?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-outline-error" onclick=${() => {handleDeleteTag(deleteTag.length > 0 ? deleteTag : null)}} >Delete</button>
              </div>
            </div>
          </div>
        </div>
        
      </div>


  `;
}


export default AssetTags;
